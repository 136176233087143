import { Utils } from "./utils";
import { SelectItem } from "primeng/api";

export class Constants {
  static getCountryOptions(): SelectItem[] {
    return Utils.mapIdToSelectItem([
      "International",
      "Deutschland",
      "Österreich",
      "Schweiz",
      "Italien",
    ]);
  }

  static getLanguageOptions(): SelectItem[] {
    return Utils.mapIdToSelectItem(["de", "en"]);
  }

  static getLanguageOptionsFull(): SelectItem[] {
    return Utils.mapIdToSelectItem(["Deutsch", "Englisch"]);
  }

  static getCurrencyOptions(): SelectItem[] {
    return Utils.mapIdToSelectItem(["EUR", "CHF"]);
  }

}
