import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import {
  ConfirmService,
  OverlayService,
  OverlaySize,
  getPropertyPath,
} from "@incert/incert-core";
import { DataTableFilterOverlayComponent } from "./data-table-filter-overlay/data-table-filter-overlay.component";
import {
  DataSourceFilter,
  DataTableFilterComponent,
  FILTER_CONFIG,
  PersistenceService,
} from "@incert/incert-gui";
import { I18nService } from "@incert/i18n";
import { DataTableFilterDefinition } from "@incert/incert-gui";
import { ProcessedDataTableFilterDefinition } from "./interfaces/processed-data-table-filter-definition.interface";
import { Subject, Subscription, take } from "rxjs";
import { DataTableFilterProfileOverlayComponent } from "./data-table-filter-profile-overlay/data-table-filter-profile-overlay.component";
import { DataTablePersistence } from "../shared/interfaces/data-table-persistence.interface";
import { DataTableInsertFilterProfileOverlayComponent } from "./data-table-filter-profile-overlay/data-table-insert-filter-profile-overlay/data-table-insert-filter-profile-overlay.component";

@Component({
  selector: "data-table-filter-area",
  templateUrl: "./data-table-filter-area.component.html",
  styleUrls: ["./data-table-filter-area.component.scss"],
})
export class DataTableFilterAreaComponent implements OnInit {
  @Input()
  persistence: DataTablePersistence = null;
  @Input()
  persistenceService: PersistenceService = null;

  @Input()
  set filters(value: DataTableFilterDefinition<any>[]) {
    this.processFilters(value);
  }

  @Output()
  setFilter = new EventEmitter<DataSourceFilter[]>();
  @Output()
  initialized = new EventEmitter<DataSourceFilter[]>();

  columnCount = 1;
  expansion = false;
  showFilterExpansion = true;
  activeFilterCount = 0;
  processedFilters: ProcessedDataTableFilterDefinition[] = [];
  filterCreated?: Subscription;
  activeFilters = [];

  constructor(
    private overlayService: OverlayService,
    private i18n: I18nService,
    private confirmService: ConfirmService,
    private injector: Injector,
  ) {}

  ngOnInit(): void {
    this.columnCount =
      this.processedFilters.length < 4 ? this.processedFilters.length : 4;
    this.showFilterExpansion = this.processedFilters.length > this.columnCount;
  }

  async filter(instances?: DataTableFilterComponent[]) {
    const updateLocalFilters = !!instances;
    if (!instances) {
      instances = this.processedFilters.map((f) => f.instance);
    }

    const filterData = [];
    for (const instance of instances) {
      const hostData = instance ? await instance.getFilter() : null;
      if (hostData) {
        filterData.push(hostData);
        if (updateLocalFilters) {
          this.updateLocalFilters(hostData);
        } else {
          this.updateExtendedFilters(hostData);
        }
      }
    }

    const activeFilters = [];
    for (const fd of filterData) {
      if (fd.filters[0].value) {
        activeFilters.push(fd);
      }
    }
    this.activeFilterCount = activeFilters.length;
    this.activeFilters = activeFilters;
    this.setFilter.emit(this.activeFilters);

    return this.activeFilters;
  }

  updateLocalFilters(hostData) {
    for (const { instance } of this.processedFilters) {
      if (instance) {
        if (
          this.isCorrectProperty(
            getPropertyPath(instance.config.property),
            getPropertyPath(hostData.filters[0]?.property),
          )
        ) {
          instance.setValue(hostData.filters[0].value);
        }
        if (
          this.isCorrectProperty(
            instance.config.externalProperty,
            hostData.filters[0]?.externalProperty,
          )
        ) {
          instance.setValue(hostData.filters[0].value);
        }
      }
    }
    this.updateExtendedFilters(hostData);
  }

  updateExtendedFilters(hostData) {
    for (const filter of this.processedFilters) {
      if (
        this.isCorrectProperty(
          getPropertyPath(filter.property),
          getPropertyPath(hostData.filters[0]?.property),
        )
      ) {
        filter.value = hostData.filters[0].value;
      }
      if (
        this.isCorrectProperty(
          filter.externalProperty,
          hostData.filters[0]?.externalProperty,
        )
      ) {
        filter.value = hostData.filters[0].value;
      }
    }
  }

  isCorrectProperty(prop1, prop2) {
    return (
      JSON.stringify(prop1) === JSON.stringify(prop2) &&
      prop1 !== undefined &&
      prop1 !== null
    );
  }

  async clearAllConfirm() {
    const response = await this.confirmService.confirmError(
      this.i18n.instant("core.dataTable.resetFilterCheck"),
      this.i18n.instant("core.dataTable.resetFilter"),
    );
    if (response) {
      this.clearAll();
      await this.filter();
    }
  }

  clearAll() {
    for (const filter of this.processedFilters) {
      filter.value = "";
      filter.instance?.reset();
    }
  }

  private processFilters(filterDefinitions: DataTableFilterDefinition<any>[]) {
    if (this.persistence) {
      if (this.persistence.config?.filterProfiles?.length > 0) {
        const defaultFilterProfile =
          this.persistence.config.filterProfiles.find((v) => v.default);
        if (defaultFilterProfile) {
          for (const defaultFilter of defaultFilterProfile.filters) {
            filterDefinitions.map((v) => {
              if (
                defaultFilter.property === v.property?.toString() ||
                defaultFilter.property === v.externalProperty
              ) {
                v.filter.config.value = defaultFilter.value;
              }
            });
          }
        }
      }
    }

    const filters: any[] = [];
    const filterCreated = new Subject();
    for (const filter of filterDefinitions) {
      const injector = Injector.create({
        parent: this.injector,
        providers: [
          {
            provide: FILTER_CONFIG,
            useValue: {
              header: filter.header.toString(),
              property: filter.property,
              externalProperty: filter.externalProperty,
              tooltip: filter.tooltip?.toString(),
              ...filter.filter.config,
            },
          },
        ],
      });

      const newFilter: ProcessedDataTableFilterDefinition = {
        header: filter.header.toString(),
        property: filter.property,
        externalProperty: filter.externalProperty,
        component: {
          component: filter.filter.component,
          injector,
          init: (c) => {
            newFilter.instance = c;
            if (filter.filter.config.value) {
              newFilter.instance.setValue(filter.filter.config.value);
            }
            if (filters.every((f) => f.instance)) {
              filterCreated.next(true);
            }
          },
        },
      };
      filters.push(newFilter);
    }
    this.processedFilters = filters;

    this.filterCreated?.unsubscribe();
    this.filterCreated = filterCreated
      .pipe(take(1))
      .subscribe(async () => this.initialized.emit(await this.filter()));
  }

  async overlayExtendedFilter() {
    await this.overlayService.show<DataTableFilterOverlayComponent>({
      type: DataTableFilterOverlayComponent,
      size: OverlaySize.medium,
      displayAsSidebar: true,
      header: this.i18n.instant("core.dataTable.extendedFilter"),
      init: (instance) => {
        instance.filters = this.processedFilters;
      },
      actions: [
        {
          label: this.i18n.instant("core.action.abort"),
          action: () => {
            return true;
          },
          displayAsLink: true,
        },
        {
          label: this.i18n.instant("core.dataTable.toFilter"),
          action: async (instance) => {
            await this.filter(instance.instances);
            return true;
          },
        },
      ],
    });
  }

  async overlayFilterProfile() {
    await this.overlayService.show<DataTableFilterProfileOverlayComponent>({
      type: DataTableFilterProfileOverlayComponent,
      size: OverlaySize.medium,
      displayAsSidebar: true,
      header: this.i18n.instant("core.dataTable.savedFilters"),
      init: (instance) => {
        instance.filterProfiles = this.persistence.config.filterProfiles;
        instance.dtFilterAreaComponent = this;
      },
      actions: [
        {
          label: this.i18n.instant("core.action.save"),
          buttonType: "primary",
          action: async (instance: DataTableFilterProfileOverlayComponent) => {
            if (instance.filterProfiles) {
              this.persistence.config.filterProfiles = instance.filterProfiles;
              await this.persistenceService.updateDataTablePersistence(
                this.persistence,
              );
              return true;
            }
            return false;
          },
        },
        {
          label: this.i18n.instant("core.action.abort"),
          id: "abort-filter-profile-overlay",
          action: () => {
            return true;
          },
          displayAsLink: true,
        },
      ],
    });
  }

  async overlaySaveFilterProfile() {
    await this.overlayService.show<DataTableInsertFilterProfileOverlayComponent>(
      {
        type: DataTableInsertFilterProfileOverlayComponent,
        size: OverlaySize.medium,
        displayAsSidebar: true,
        header: this.i18n.instant("core.dataTable.saveCurrentFilter"),
        init: (instance) => {
          instance.processedFilters = this.processedFilters;
        },
        actions: [
          {
            label: this.i18n.instant("core.action.save"),
            buttonType: "primary",
            action: async (
              instance: DataTableInsertFilterProfileOverlayComponent,
            ) => {
              if (instance.profile) {
                this.persistence.config.filterProfiles.push(instance.profile);
                await this.persistenceService.updateDataTablePersistence(
                  this.persistence,
                );
                return true;
              }
              return false;
            },
          },
          {
            label: this.i18n.instant("core.action.abort"),
            displayAsLink: true,
            action: () => {
              return true;
            },
          },
        ],
      },
    );
  }
}
