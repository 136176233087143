import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IconModule } from "../../icon/icon.module";
import { BrowserModule } from "@angular/platform-browser";
import { TooltipModule } from "primeng/tooltip";
import { DataTablePasswordFieldComponent } from "./data-table-password-field.component";
import { FormsModule } from "@angular/forms";
import { IconButtonModule } from "../../icon-button/icon-button.module";
import { I18nModule } from "@incert/i18n";

@NgModule({
  declarations: [DataTablePasswordFieldComponent],
  imports: [
    BrowserModule,
    CommonModule,
    IconModule,
    TooltipModule,
    FormsModule,
    IconButtonModule,
    I18nModule,
  ],
  exports: [DataTablePasswordFieldComponent],
})
export class DataTablePasswordFieldModule {}
