<div class="data-table-filter-container separator-bottom">
  <div
    class="data-table-filter separator-bottom">
    <div class="data-table-filter-inner">
      <div class="grid">
        <ng-container *ngFor="let f of processedFilters; index as i">
          <div [class]="'md:col-6 sm:col-12 lg:col-' + 12/columnCount + ' col-' + 12/columnCount"
               (keydown.enter)="filter()"
               [hidden]="(!expansion && i >= columnCount) || i >= 12">
            <component-outlet
              [config]="f.component">
            </component-outlet>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="data-table-filter-actions">
      <icon-button
        (click)="filter()"
        tooltip="{{'core.dataTable.toFilter' | i18n}}"
        tooltipPosition="left" iconType="search" iconOnly="true" displayIconButtonAsButton="true">
      </icon-button>
      <icon-button
        *ngIf="this.processedFilters.length > 12"
        (click)="overlayExtendedFilter()"
        tooltip="{{'core.dataTable.extendedFilter' | i18n}}"
        tooltipPosition="left" iconType="filter" iconOnly="true" displayIconButtonAsButton="true">
      </icon-button>
      <icon-button
        *ngIf="expansion && showFilterExpansion"
        (click)="expansion = !expansion"
        tooltip="{{'core.dataTable.showLess' | i18n}}"
        tooltipPosition="left" iconType="openAccordion" iconOnly="true" displayIconButtonAsButton="true">
      </icon-button>
      <icon-button
        *ngIf="!expansion && showFilterExpansion"
        (click)="expansion = !expansion"
        tooltip="{{'core.dataTable.showMore' | i18n}}"
        tooltipPosition="left" iconType="closeAccordion" iconOnly="true" displayIconButtonAsButton="true">
      </icon-button>
    </div>
  </div>
  <div class="button-links-group-footer">
    <p-button
      [label]="('core.dataTable.activeFilters' | i18n) + ' (' + activeFilterCount + ')'"
      (click)="clearAllConfirm()"
      styleClass="reset-button-to-link">
    </p-button>
    <p-button
      *ngIf="persistence?.config?.filterProfiles?.length > 0"
      label="{{'core.dataTable.savedFilters' | i18n}}"
      (click)="overlayFilterProfile()"
      styleClass="reset-button-to-link">
    </p-button>
    <p-button
      *ngIf="persistenceService"
      label="{{'core.dataTable.saveCurrentFilter' | i18n}}"
      (click)="overlaySaveFilterProfile()"
      styleClass="reset-button-to-link">
    </p-button>
  </div>
</div>
