<p-dropdown
  *ngIf="config.type==='eq'"
  appendTo="body"
  [formControl]="form.get('filter')"
  [placeholder]="config.header"
  [pTooltip]="config.tooltip"
  tooltipPosition="bottom"
  [options]="options"
  [showClear]="true"
  [filter]="config.filter">
</p-dropdown>

<p-multiSelect
  *ngIf="config.type==='in'"
  appendTo="body"
  [formControl]="form.get('filter')"
  [placeholder]="config.header"
  [pTooltip]="config.tooltip"
  tooltipPosition="bottom"
  [options]="options"
  [maxSelectedLabels]="3"
  [selectedItemsLabel]="'core.multiSelect.selectedItemsLabel' | i18n"
></p-multiSelect>
