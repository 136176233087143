import { catchError } from "rxjs/operators";

import { Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { NotificationService } from "@incert/incert-core";
import { HttpClient } from "@angular/common/http";

@Injectable({ providedIn: "root" })
export class HttpService {
  constructor(
    private http: HttpClient,
    private injector: Injector,
    private notificationService: NotificationService,
  ) {}

  private router: Router;

  public get<T>(url: any, options?: any): Promise<T> {
    return <Promise<T>>this.http
      .get(url, options)
      .pipe(catchError((e) => this.catchError(e)))
      .toPromise();
  }

  public post<T>(url: any, data: any, options?: any): Promise<T> {
    return <Promise<T>>this.http
      .post(url, data, options)
      .pipe(catchError((e) => this.catchError(e)))
      .toPromise();
  }

  public put<T>(url: any, data: any, options?: any): Promise<T> {
    return <Promise<T>>this.http
      .put(url, data, options)
      .pipe(catchError((e) => this.catchError(e)))
      .toPromise();
  }

  public patch<T>(url: any, data: any, options?: any): Promise<T> {
    return <Promise<T>>this.http
      .patch(url, data, options)
      .pipe(catchError((e) => this.catchError(e)))
      .toPromise();
  }

  public delete<T>(url: any, options?: any): Promise<T> {
    return <Promise<T>>this.http
      .delete(url, options)
      .pipe(catchError((e) => this.catchError(e)))
      .toPromise();
  }

  private catchError(err: any): Observable<{}> {
    if (this.router == null) {
      this.router = this.injector.get(Router);
    }

    switch (err.status) {
      case 404: {
        if (err.error.message) {
          this.notificationService.notifyError(
            "Resource existiert nicht " + err.message,
            "Fehler beim Laden",
          );
        }
        break;
      }
      case 400: {
        if (err?.errror?.message.blocked) {
          this.notificationService.notifyError(
            err.error.message.blocked +
              " until " +
              new Date(err.error.message.blockedUntil).toTimeString(),
            "User Account gesperrt",
          );
        }
        break;
      }
      case 401: {
        this.redirectIfUnauthorized();
        break;
      }
      case 403: {
        this.forbiddenAction();
      }
    }

    return throwError(err);
  }

  private forbiddenAction() {
    this.notificationService.notifyError(
      "Fehlende Berechtigungen.",
      "Zugang verweigert",
    );
    this.router.navigate(["/"]);
    localStorage.clear();
  }

  private redirectIfUnauthorized() {
    if (localStorage.getItem("USER_ID")) {
      this.notificationService.notifyError(
        "Bitte erneut einloggen. Möglicherweise ist ihre Session abgelaufen.",
        "Nicht Authentifiziert",
      );
    }
    this.router.navigate(["/"]);
    localStorage.clear();
  }
}
