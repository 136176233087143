import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IconModule } from "../../icon/icon.module";
import { BrowserModule } from "@angular/platform-browser";
import { TooltipModule } from "primeng/tooltip";
import { DataTableHoverTextComponent } from "./data-table-hover-text.component";

@NgModule({
  declarations: [DataTableHoverTextComponent],
  imports: [BrowserModule, CommonModule, IconModule, TooltipModule],
  exports: [DataTableHoverTextComponent],
})
export class DataTableHoverTextModule {}
