import { Component, OnInit } from "@angular/core";
import { ResourceService } from "../shared/resource.service";
import { Resource, ResourceData, ResourceType } from "../shared/resource.model";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { createNameof, NotificationService } from "@incert/incert-core";
import { markAsDirty, modelToForm } from "@incert/incert-gui";
import { SelectItem } from "primeng/api";
import { APIService } from "../../../../core/api.service";
import { Constants } from "../../../../core/constants";

@Component({
  selector: "incert-resource-upsert",
  templateUrl: "./resource-upsert.component.html",
  styleUrls: ["./resource-upsert.component.scss"],
})
export class ResourceUpsertComponent implements OnInit {
  resource: Resource;
  resourceTypes: ResourceType[] = [];
  filesToDelete: any[] = [];
  resourceId: number;
  formGroup: UntypedFormGroup;
  typeSI: SelectItem[] = [];
  languageSI: SelectItem[] = [];
  nameofForm = createNameof<Resource>();
  nameofFormCategory = createNameof<ResourceType>();
  nameofFormData = createNameof<ResourceData>();

  constructor(
    private resourceService: ResourceService,
    private notificationService: NotificationService,
    private settingsService: APIService,
    private fb: UntypedFormBuilder,
  ) {
    this.createFormGroup();
    this.addResourceData();
  }

  async ngOnInit() {
    if (this.resourceId) {
      this.resource = await this.resourceService.getResource(this.resourceId);
      for (let i = 0; i < this.resource.resourceDataArray.length - 1; i++) {
        this.addResourceData();
      }
      this.formGroup.patchValue(this.resource);
    }
    this.languageSI = Constants.languageOptions();
  }

  private createFormGroup() {
    this.formGroup = this.fb.group(
      modelToForm<Resource>({
        id: this.fb.control(0),
        short: this.fb.control("", Validators.required),
        resourceType: this.fb.group(
          modelToForm<ResourceType>({
            id: this.fb.control(0),
            type: this.fb.control("", Validators.required),
            group: this.fb.control(""),
            groupCategory: this.fb.control("text"),
          }),
        ),
        resourceDataArray: this.fb.array([]),
      }),
    );
  }

  public addResourceData() {
    this.resourceDataArray.push(
      this.fb.group(
        modelToForm<ResourceData>({
          id: this.fb.control(0),
          language: this.fb.control("de", Validators.required),
          data: this.fb.control(""),
          fileData: this.fb.control(""),
          name: this.fb.control(""),
          alt: this.fb.control(""),
          copyright: this.fb.control(""),
        }),
      ),
    );
  }

  public deleteResourceData(index: number) {
    if (this.resourceDataArray.length === 1) {
      this.notificationService.notifyInfo(
        "Mindestens eine Übersetzung muss angegeben werden",
        "Löschen nicht möglich",
      );
    } else {
      try {
        if (this.resourceTypeGroupCategory === "file") {
          const data = this.resourceDataArray[index]?.value?.data;
          if (data) {
            this.filesToDelete.push(data);
          }
        }
        this.resourceDataArray.removeAt(index);
      } catch (e) {
        this.notificationService.notifyError(e);
      }
    }
  }

  async upsertResource() {
    markAsDirty(this.formGroup);
    if (this.formGroup.valid) {
      try {
        if (this.resourceTypeGroupCategory === "file") {
          for (const fileToDelete of this.filesToDelete) {
            await this.resourceService.deleteFile(fileToDelete);
          }
          for (const resourceData of this.resourceDataArray.value) {
            if (resourceData?.fileData) {
              const file: File = resourceData.fileData[0];
              const uploaded = await this.resourceService.uploadFile(file);
              if (uploaded) {
                resourceData.data = uploaded;
              }
            }
          }
        }
        await this.resourceService.upsertResource(this.formGroup.value);
        return true;
      } catch (e) {
        this.notificationService.notifyError(e);
        return false;
      }
    }
  }

  changeResourceType() {
    const resourceType = this.resourceTypes.find((v) => {
      return this.resourceType.value.type === v.type;
    });
    this.resourceType.patchValue(resourceType);
  }

  openResource(fileName: string) {
    window.open(
      this.settingsService.mediaLibraryUrl + "/global/" + fileName,
      "_blank",
    );
  }

  get resourceDataArray() {
    return this.formGroup.get("resourceDataArray") as UntypedFormArray;
  }

  get resourceType() {
    return this.formGroup.get("resourceType") as UntypedFormGroup;
  }

  get resourceTypeGroupCategory() {
    return this.formGroup.value.resourceType.groupCategory;
  }
}
