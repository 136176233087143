<ul>
  <ng-container *ngFor="let element of iconConfig">
    <li *ngIf="!element.hidden && show(element)">
        <icon-button [iconType]="element.icon"
                     [label]="element.label"
                     [pTooltip]='element.tooltip'
                     [tooltipPosition]="element.tooltipPosition ?? 'top'"
                     (click)="element.onClick(row_data)"
                     [iconOnly]="element.iconOnly"
                     [disabled]="disable(element)"
                     displayIconButtonAsButton="true"></icon-button>
    </li>
  </ng-container>
  <li *ngIf="contextMenuItems" class="data-table-icon-buttons-context-menu">
    <icon-button iconType="contextMenu"
                  label="Mehr"
                  iconOnly="true"
                 (click)="contextMenu.toggle($event)"
                 displayAsLinkButtonHeight="true"></icon-button>
    <p-menu #contextMenu [popup]="true" [model]="contextMenuItems" [appendTo]="'body'"></p-menu>
  </li>
</ul>
