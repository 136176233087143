import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { createNameof, NotificationService } from "@incert/incert-core";
import { SelectItem } from "primeng/api";
import { ContactGroup } from "../contact-group.model";
import { ContactGroupManagerService } from "../contact-group-manager.service";
import {
  ContactGroupComponent,
  ContactGroupFrequency,
} from "../contact-group.component";
import { ContactEmail } from "../../contact-email/contact-email.model";
import { markAsDirty, modelToForm } from "@incert/incert-gui";

@Component({
  selector: "incert-internal-tools-contact-group-add",
  templateUrl: "./contact-group-add.component.html",
})
export class ContactGroupAddComponent implements OnInit {
  nameofForm = createNameof<ContactGroup>();
  contactGroupComp: ContactGroupComponent;
  contactRoles: SelectItem[] = [];
  accountSystemGroups: any[] = [];
  reportDashboards: SelectItem[] = [];
  contacts: ContactEmail[];
  contactGroup: ContactGroup;
  form: UntypedFormGroup;
  typeSI: SelectItem[] = [{ value: "report", label: "Report" }];
  weekdaySI: SelectItem[] = [
    { value: "0", label: "Sonntag" },
    { value: "1", label: "Montag" },
    { value: "2", label: "Dienstag" },
    { value: "3", label: "Mittwoch" },
    { value: "4", label: "Donnerstag" },
    { value: "5", label: "Freitag" },
    { value: "6", label: "Samstag" },
  ];
  frequencySI: SelectItem[] = [
    { value: ContactGroupFrequency.DAILY, label: "Täglich" },
    { value: ContactGroupFrequency.WEEKLY, label: "Wöchentlich" },
    { value: ContactGroupFrequency.MONTHLY, label: "Monatlich" },
  ];
  protected readonly ContactGroupFrequency = ContactGroupFrequency;

  constructor(
    private fb: UntypedFormBuilder,
    private notificationService: NotificationService,
    private contactGroupManager: ContactGroupManagerService,
  ) {
    this.form = this.fb.group(
      modelToForm<ContactGroup>({
        id: [null],
        name: [null, [Validators.required]],
        type: [null, [Validators.required]],
        contactRoles: [null, [Validators.required]],
        accountSystemGroup: [null, [Validators.required]],
        dashboardIds: [null],
        startDate: [null],
        endDate: [null],
        frequency: [null],
        frequencyDetail: [null],
      }),
    );
  }

  async ngOnInit() {
    if (this.contactGroup) {
      this.form.patchValue(this.contactGroup);
      this.form
        .get("contactRoles")
        .patchValue(this.contactGroup.contactRoles.map((c) => c.id));
      this.form
        .get("accountSystemGroup")
        .patchValue(
          this.contactGroup.accountSystemGroup
            ? this.contactGroup.accountSystemGroup.id
            : null,
        );
      this.form
        .get("startDate")
        .patchValue(
          this.contactGroup?.startDate
            ? new Date(this.contactGroup.startDate)
            : null,
        );
      this.form
        .get("endDate")
        .patchValue(
          this.contactGroup?.endDate
            ? new Date(this.contactGroup.endDate)
            : null,
        );
    }
  }

  async upsertContactGroup() {
    markAsDirty(this.form);
    if (this.form.valid) {
      const contactGroup: ContactGroup = this.form.value;
      contactGroup.contactRoles = this.form
        .get("contactRoles")
        .value.map((v) => {
          return {
            id: v,
          };
        });
      contactGroup.accountSystemGroup = {
        id: this.form.get("accountSystemGroup").value,
      };
      contactGroup.dashboardIds = JSON.stringify(
        this.form.get("dashboardIds").value,
      );

      await this.contactGroupManager.upsertContactGroup(contactGroup);
      this.notificationService.notifySuccess(
        this.contactGroup
          ? "Kontaktgruppe bearbeitet"
          : "Kontaktgruppe hinzugefügt",
      );
      await this.contactGroupComp.init();
      return true;
    }
    return false;
  }

  isType(type: string) {
    return this.form.get("type")?.value === type;
  }

  isFrequency(frequency: string) {
    return this.form.get("frequency")?.value === frequency;
  }
}
