import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IconModule } from "../../icon/icon.module";
import { DataTableIconButtonsComponent } from "./data-table-icon-buttons.component";
import { BrowserModule } from "@angular/platform-browser";
import { TooltipModule } from "primeng/tooltip";
import { IconButtonModule } from "../../..";
import { MenuModule } from "primeng/menu";

@NgModule({
  declarations: [DataTableIconButtonsComponent],
  imports: [
    BrowserModule,
    CommonModule,
    IconModule,
    TooltipModule,
    IconButtonModule,
    MenuModule,
  ],
  exports: [DataTableIconButtonsComponent],
})
export class DataTableIconButtonsModule {}
