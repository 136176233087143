import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PageComponent } from "./page.component";
import { PageContentComponent } from "./page-content/page-content.component";
import { PageStepComponent } from "./page-step/page-step.component";
import { BottombarModule } from "../bottombar/bottombar.module";
import { PageInnerContainerComponent } from "./page-inner-container/page-inner-container.component";
import { IconButtonModule } from "../icon-button/icon-button.module";
import { PanelModule } from "primeng/panel";
import { StepsModule } from "primeng/steps";
import { ButtonModule } from "primeng/button";
import { BreadcrumbModule } from "primeng/breadcrumb";
import { IconModule } from "../icon/icon.module";
import { I18nModule } from "@incert/i18n";

@NgModule({
  imports: [
    CommonModule,
    PanelModule,
    StepsModule,
    ButtonModule,
    BottombarModule,
    BreadcrumbModule,
    IconButtonModule,
    IconModule,
    I18nModule,
  ],
  declarations: [
    PageComponent,
    PageContentComponent,
    PageStepComponent,
    PageInnerContainerComponent,
  ],
  exports: [
    PageComponent,
    PageContentComponent,
    PageStepComponent,
    PageInnerContainerComponent,
  ],
})
export class PageModule {}
