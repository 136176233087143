import { Component, Inject, OnInit } from "@angular/core";
import {
  NotificationService,
  OverlayService,
  OverlaySize,
} from "@incert/incert-core";
import { AccountSystem } from "../../../core/models/account-system.interface";
import {
  ArrayDataSource,
  createComponentConfiguration,
  DataTableConfig,
  DataTableIconButtonsComponent,
} from "@incert/incert-gui";

import { IconType } from "@incert/incert-core";
import { AccountSystemAction } from "./account-system-action.interface";
import { MemoAdd } from "../../tools/memo/memo-add/memo-add.component";
import { AddNotificationComponent } from "../../tools/notification/add-notification/add-notification.component";
import { AccountSystemManager } from "../account-system-manager.service";
import { Subscription } from "rxjs";
import { SystemValueImportComponent } from "../../tools/system-value/system-value-import/system-value-import.component";
import { IAuthManager } from "../../../core/auth";
import { Utils } from "../../../core/utils";

@Component({
  selector: "incert-internal-tools-account-system-action",
  templateUrl: "./account-system-action.component.html",
  styleUrls: ["./account-system-action.component.scss"],
})
export class AccountSystemActionComponent implements OnInit {
  public accountSystemsAll: Array<AccountSystem>;
  public accountSystemsSelectedDS: ArrayDataSource<AccountSystem>;
  public accountSystemsNotSelectedDS: ArrayDataSource<AccountSystem>;
  public accountSystemsSelectedConfig: DataTableConfig<AccountSystem>;
  public accountSystemsNotSelectedConfig: DataTableConfig<AccountSystem>;

  public IconType = IconType;
  public showSystems = false;
  public _actionConfig: Array<AccountSystemAction>;
  public subscription: Subscription;

  constructor(
    @Inject("AuthManager") private authManager: IAuthManager,
    private overlayService: OverlayService,
    private accountSystemManager: AccountSystemManager,
    private notificationService: NotificationService,
  ) {}

  async ngOnInit() {
    await this.setActionConfig();
  }

  public async initialize(
    selectedSystems: Array<AccountSystem>,
    allSystems: Array<AccountSystem>,
  ) {
    this.accountSystemsAll = allSystems;
    const accountSystemsNotSelected = this.intersect(
      allSystems,
      selectedSystems,
    );
    this.accountSystemsSelectedDS = new ArrayDataSource<AccountSystem>(
      selectedSystems,
    );
    this.accountSystemsNotSelectedDS = new ArrayDataSource<AccountSystem>(
      accountSystemsNotSelected,
    );

    this.initializeSelectedAccountSystemTable();
    this.initializeNotSelectedAccountSystemTable();
  }

  public initializeSelectedAccountSystemTable() {
    this.accountSystemsSelectedConfig = {
      data: this.accountSystemsSelectedDS,
      rows: 10,
      mode: "pagination",
      emptyMessage: "Alle Kundensysteme ausgewählt",
      columns: [
        {
          header: "Id",
          property: (v) => v.get("id"),
        },
        {
          header: "Kürzel",
          property: (v) => v.get("short"),
        },
        {
          header: "Name",
          property: (v) => v.get("name"),
        },
        {
          header: "Aktionen",
          component: createComponentConfiguration(
            DataTableIconButtonsComponent,
            {
              iconConfig: [
                {
                  icon: "delete",
                  onClick: (accountSystem: AccountSystem) =>
                    this.removeAccountSystem(accountSystem),
                  tooltip: "Löschen",
                },
              ],
            },
          ),
        },
      ],
    };
  }

  public initializeNotSelectedAccountSystemTable() {
    this.accountSystemsNotSelectedConfig = {
      data: this.accountSystemsNotSelectedDS,
      rows: 10,
      mode: "pagination",
      emptyMessage: "Alle Kundensysteme ausgewählt",
      columns: [
        {
          header: "Id",
          property: (v) => v.get("id"),
        },
        {
          header: "Kürzel",
          property: (v) => v.get("short"),
        },
        {
          header: "Name",
          property: (v) => v.get("name"),
        },
        {
          header: "Aktionen",
          component: createComponentConfiguration(
            DataTableIconButtonsComponent,
            {
              iconConfig: [
                {
                  icon: "plus",
                  onClick: (accountSystem: AccountSystem) =>
                    this.addAccountSystem(accountSystem),
                  tooltip: "Hinzufügen",
                },
              ],
            },
          ),
        },
      ],
    };
  }

  private addAccountSystem(accountSystem: AccountSystem) {
    this.accountSystemsNotSelectedDS.reloadDataTable(
      this.accountSystemsNotSelectedDS
        .getData()
        .filter((a) => a.id !== accountSystem.id),
    );
    this.accountSystemsSelectedDS.reloadDataTable([
      ...this.accountSystemsSelectedDS.getData(),
      accountSystem,
    ]);
  }

  private removeAccountSystem(accountSystem: AccountSystem) {
    this.accountSystemsSelectedDS.reloadDataTable(
      this.accountSystemsSelectedDS
        .getData()
        .filter((a) => a.id !== accountSystem.id),
    );
    this.accountSystemsNotSelectedDS.reloadDataTable([
      ...this.accountSystemsNotSelectedDS.getData(),
      accountSystem,
    ]);
  }

  private intersect(
    allSystems: Array<AccountSystem>,
    selectedSystems: Array<AccountSystem>,
  ) {
    return allSystems.filter(
      (a) => selectedSystems.map((s) => s.id).indexOf(a.id) === -1,
    );
  }

  public toggleShowSystems() {
    this.showSystems = !this.showSystems;
  }

  public async resetSelectedSystems() {
    this.accountSystemsSelectedDS.data = [];
    await this.initialize([], this.accountSystemsAll);
  }

  private async setActionConfig() {
    this._actionConfig = [
      {
        name: "Wissensnotiz erstellen",
        hidden: !this.writeMemo,
        description: "",
        icon: "memo",
        action: () => this.actionMemo(),
      },
      {
        name: "Notification erstellen",
        hidden: !this.writeNotification,
        description: "",
        icon: "notification",
        action: () => this.actionNotification(),
      },
      {
        name: "Systemwerte neu importieren",
        hidden: !this.writeSystemValue,
        description: "",
        icon: "import",
        action: () => this.actionImportConfigurations(),
      },
      {
        name: "Systeme exportieren",
        description: "",
        icon: "csvFile",
        action: () => null,
        subAction: [
          {
            action: () => this.actionExport(),
            icon: "accountSystem",
            tooltip: "Kundensysteme",
          },
          {
            action: () => this.actionExportAccounts(),
            icon: "user",
            tooltip: "Kunden",
          },
        ],
      },
      {
        name: "Auswahl bearbeiten",
        description: "",
        backgroundColor: "#f8f8f8",
        icon: "accountSystem",
        action: () => this.toggleShowSystems(),
      },
    ];
  }

  public async actionMemo() {
    await this.overlayService.show<MemoAdd>({
      type: MemoAdd,
      size: OverlaySize.large,
      init: (c) =>
        c.onAction(
          this.accountSystemsAll,
          this.accountSystemsSelectedDS.getData(),
        ),
      header: "Wissensnotiz erstellen",
      actions: [
        {
          buttonType: "primary",
          label: "Speichern",
          action: (c) => c.onSaveNewMemo(),
        },
        {
          displayAsLink: true,
          label: "Abbrechen",
          action: () => true,
        },
      ],
    });
  }

  public async actionNotification() {
    await this.overlayService.show<AddNotificationComponent>({
      type: AddNotificationComponent,
      size: OverlaySize.large,
      header: "Notification hinzufügen",
      init: (c) =>
        c.onAction(
          this.accountSystemsAll,
          this.accountSystemsSelectedDS.getData(),
        ),
      actions: [
        { label: "Abbrechen", displayAsLink: true, action: () => true },
        { label: "Speichern", action: (c) => c.upsertNotification() },
      ],
    });
  }

  public async actionImportConfigurations() {
    await this.overlayService.show<SystemValueImportComponent>({
      type: SystemValueImportComponent,
      header: "Import bestätigen",
      init: (type) =>
        (type.accountSystems = this.accountSystemsSelectedDS.getData()),
      actions: [
        {
          label: "Daten anfordern",
          iconType: "import",
          action: () => {
            try {
              this.accountSystemManager.startImport({
                accountSystems: [
                  ...this.accountSystemsSelectedDS
                    .getData()
                    .map((a) => a.short),
                ],
              });
              this.notificationService.notifySuccess(
                "Import erfolgreich gestartet",
                "Import gestartet",
              );
              return true;
            } catch (e) {
              this.notificationService.notifySuccess(
                "Import erfolgreich gestartet",
                "Import gestartet",
              );
              return true;
            }
          },
        },
        {
          label: "Abbrechen",
          displayAsLink: true,
          iconType: "error",
          action: () => true,
        },
      ],
    });
  }

  private actionExport() {
    const excludeFromExport: Array<keyof AccountSystem> = [
      "key",
      "memos",
      "properties",
    ];

    this.accountSystemsSelectedDS.getData().forEach((system: AccountSystem) => {
      excludeFromExport.forEach((excludeProperty) => {
        delete system[excludeProperty];
      });

      system.accountSystemSubDomains.forEach((subdomain) => {
        delete subdomain.secret;
      });
    });

    Utils.createCSV(
      this.accountSystemsSelectedDS.getData(),
      "accountsystems-" + new Date().toISOString().split("T")[0] + ".csv",
    );
  }

  private actionExportAccounts() {
    const data = [];
    this.accountSystemsSelectedDS.getData().forEach((acs) => {
      if (!data.find((d) => d.id === acs.account.id)) {
        data.push(acs.account);
      }
    });

    Utils.createCSV(
      data,
      "accounts-" + new Date().toISOString().split("T")[0] + ".csv",
    );
  }

  get actionConfig(): Array<AccountSystemAction> {
    if (this._actionConfig) {
      return this._actionConfig.filter((actionConfig) => !actionConfig.hidden);
    } else {
      return [];
    }
  }

  public get writeMemo(): boolean {
    return this.authManager.hasPermission("writeMemo");
  }

  public get writeNotification(): boolean {
    return this.authManager.hasPermission("writeNotification");
  }

  public get writeSystemValue(): boolean {
    return this.authManager.hasPermission("writeSystemValue");
  }
}
