import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SelectFilterComponent } from "./select-filter.component";
import { ReactiveFormsModule } from "@angular/forms";
import { DropdownModule } from "primeng/dropdown";
import { MultiSelectModule } from "primeng/multiselect";
import { I18nModule } from "@incert/i18n";
import { TooltipModule } from "primeng/tooltip";

@NgModule({
  declarations: [SelectFilterComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DropdownModule,
    MultiSelectModule,
    I18nModule,
    TooltipModule,
  ],
})
export class SelectFilterModule {}
