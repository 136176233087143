import { Directive, Inject } from "@angular/core";
import { INCERT_GUI_I18N, IncertGUII18n } from "../../incert-gui-i18n.token";
import { MultiSelect } from "primeng/multiselect";

@Directive({
  selector: "[pMultiSelectI18n]",
})
export class MultiSelectI18nDirective {
  constructor(
    private multiSelect: MultiSelect,
    @Inject(INCERT_GUI_I18N) private config: IncertGUII18n,
  ) {
    this.translate();
  }

  private translate() {
    this.multiSelect.selectedItemsLabel =
      this.config.multiSelect.selectedItemsLabel;
    this.multiSelect.defaultLabel = this.config.multiSelect.defaultLabel;
  }
}
