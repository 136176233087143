<ng-container *ngFor='let subdomain of accountSystem.accountSystemSubDomains' class='subdomain-link-container'>
  <div class='nowrap'>
    <a *ngIf='subdomain.subdomainLinkToSystem !== "" && subdomain.secret && loginPermission'
       target='_blank'
       class='reset-link-color clear-border'
       href='https://devoauth.incert.at/oauth/authorize?client_id={{subdomain.clientId}}&redirect_uri=https%3A%2F%2F{{subdomain.subdomainLinkToSystem}}%2Foauth_login.php&response_type=code&scope=login-accounts-system&language_code=en`'>
      <incert-icon
        class='icon-only'
        tooltipPosition='top'
        pTooltip='Login'
        iconType='logout'>
      </incert-icon>
    </a>
    <a [attr.href]="'https://' + subdomain.subdomainLinkToSystem + '/admin'"
       target='_blank'
       tooltipPosition='top'
       pTooltip='Admin Login'
       class='reset-link-color clear-border'>
      <incert-icon
        class='icon-only'
        iconType='monitor'>
      </incert-icon>
    </a>
    <a [attr.href]="'https://' + subdomain.subdomainLinkToSystem + '?noredirect=1'"
       target='_blank'
       pTooltip='No Redirect'
       tooltipPosition='top'
       class='reset-link-color clear-border'>
      <incert-icon
        class='icon-only'
        iconType='upload'> {{ subdomain.subdomainLinkToSystem }}
      </incert-icon>
    </a>
    <a href='javascript:void(0)'
       (click)='copyToClipboard(subdomain.subdomainLinkToSystem)'
       class='reset-link-color clear-border'
       tooltipPosition='top'
       pTooltip='URI Kopieren'
       aria-label=''>
      <incert-icon
        iconType='copyLight'>
        {{ subdomain }}
      </incert-icon>
    </a>
    <ng-container *ngIf='subdomain.proxy'>
      <a [attr.href]="'https://' + subdomain.proxy"
         class='clear-border'
         target='_blank'>
        <p class='proxy-link p-m-0 p-pl-3'>{{subdomain.proxy | urlSubdomain: 40}}</p>
      </a>
    </ng-container>
    <ng-container *ngIf='(!subdomain.proxy && subdomain.subdomainLinkToSystem)'>
      <a [attr.href]="'https://' + subdomain.subdomainLinkToSystem"
         target='_blank'
         class='clear-border'
         [ngStyle]="{'fontWeight': subdomain.mainSubDomain ? 'bold' : '' }"
         [pTooltip]='subdomain.subdomainLinkToSystem.length > MAX_LINK_LENGTH ? subdomain.subdomainLinkToSystem : null'>
        <p class='p-m-0 p-pl-3'> {{ subdomain.subdomainLinkToSystem | urlSubdomain: 40}}</p>
      </a>
    </ng-container>
  </div>
</ng-container>
