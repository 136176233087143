<div class="align-center" *ngIf="!hidden">
  <div *ngIf="option.text"> {{ option.text }}</div>
  <incert-icon
    class="pointer"
    *ngIf="!option.text"
    [iconType]="option.icon"
    [style.color]="option.color ?? ''"
    [ngClass]="option.class ?? 'p-d-inline-flex p-jc-center'"
    [pTooltip]="option.hint"
    [ngStyle]="{'transform': 'rotate('+option.rotation+'deg)'}"
    (click)="option.onClick(row)">
  </incert-icon>
</div>
