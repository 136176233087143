import { Component, Inject, Input, OnInit } from "@angular/core";
import {
  AccountSystem,
  AccountSystemSubdomain,
} from "../../../core/models/account-system.interface";
import { AccountSystemManager } from "../account-system-manager.service";
import { SelectItem } from "primeng/api";
import { NotificationService } from "@incert/incert-core";
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Globals } from "../../../core/globals";
import { AuthManager, IAuthManager } from "../../../core/auth";
import { Constants } from "../../../core/constants";
import { Account } from "../../../core/models/account.model";
import { InstallationStatusEnum } from "../../../core/enums/installation-status.enum";
import { DiscrEnum } from "../../../core/enums/discr.enum";
import { AccountService } from "../../../core/services/account.service";
import { markAsDirty, modelToForm } from "@incert/incert-gui";
import { firstValueFrom } from "rxjs";
import { SystemTypeEnum } from "../../../core/enums/system-type.enum";

@Component({
  selector: "sac-add-new-account-system",
  templateUrl: "./account-system-new.component.html",
  styleUrls: ["./account-system-new.component.scss"],
})
export class AccountSystemNewComponent implements OnInit {
  @Input() accountSystems: Array<AccountSystem>;
  public accounts: Array<Account> = [];
  public portalNameSI: SelectItem[];
  public clusterSI: SelectItem[];
  public InstallationStatusEnum = InstallationStatusEnum;
  public DiscrEnum = DiscrEnum;
  public formGroup: UntypedFormGroup;
  public accountSystem: AccountSystem;

  constructor(
    public globals: Globals,
    private accountSystemManager: AccountSystemManager,
    private notificationService: NotificationService,
    public auth: AuthManager,
    private fb: UntypedFormBuilder,
    private accountService: AccountService,
    @Inject("AuthManager") public authManager: IAuthManager,
  ) {
    this.formGroup = this.fb.group(
      modelToForm<AccountSystem>({
        account: new UntypedFormControl(null, Validators.required),
        name: new UntypedFormControl("", Validators.required),
        short: new UntypedFormControl("", [
          Validators.required,
          Validators.pattern("^((?!\\s).)*$"),
        ]),
        discr: new UntypedFormControl(DiscrEnum.ecom, Validators.required),
        systemType: new UntypedFormControl(
          SystemTypeEnum.professional,
          Validators.required,
        ),
        isDev: new UntypedFormControl(false, Validators.required),
        portalName: new UntypedFormControl("p1_1911", Validators.required),
        cluster: new UntypedFormControl("c1", Validators.required),
        availableForDWH: new UntypedFormControl(false),
        accountSystemSubDomains: this.fb.array([]),
      }),
    );
  }

  async ngOnInit() {
    this.accounts = await firstValueFrom(this.accountService.accounts$);
    if (this.accountSystem) {
      this.patchFormGroup(this.accountSystem);
    }
  }

  private patchFormGroup(accountSystem: AccountSystem) {
    accountSystem.account = this.accounts.find(
      (a: Account) => a.id === +accountSystem.account.id,
    );
    this.formGroup.patchValue(accountSystem);
    accountSystem.accountSystemSubDomains.forEach(
      (subdomain: AccountSystemSubdomain) => {
        this.addSubdomain(subdomain);
      },
    );
  }

  private validateFormGroup(): boolean {
    markAsDirty(this.formGroup);
    if (!this.formGroup.valid) {
      return false;
    } else {
      const accountId = this.formGroup.get("account").value["id"];
      this.formGroup
        .get("account")
        .patchValue(this.accounts.find((a) => a.id === accountId));
    }
    return true;
  }

  public async upsertAccountSystem(): Promise<boolean> {
    if (this.validateFormGroup()) {
      const error: string = await this.accountSystemManager.upsertAccountSystem(
        {
          ...this.formGroup.value,
          ...{
            installationStatus: this.accountSystem?.installationStatus,
            id: this.accountSystem?.id,
          },
        },
      );
      if (error) {
        if (this.accountSystem) {
          this.notificationService.notifyError(
            error,
            "Kundensystem konnte nicht aktualisiert werden",
          );
        } else {
          this.notificationService.notifyError(
            error,
            "Kundensystem konnte nicht erstellt werden",
          );
        }
        return false;
      }
      if (this.accountSystem) {
        this.notificationService.notifySuccess(
          error,
          "Kundensystem wurde aktualisiert",
        );
      } else {
        this.notificationService.notifySuccess(
          error,
          "Kundensystem wurde erstellt",
        );
      }
      this.accountSystemManager.markForChange();
      return true;
    }
  }

  addSubdomain(subdomain: AccountSystemSubdomain = null) {
    const subdomainFormGroup = this.fb.group(
      modelToForm<AccountSystemSubdomain>({
        id: new UntypedFormControl(subdomain?.id),
        mainSubDomain: new UntypedFormControl(
          subdomain ? subdomain.mainSubDomain : false,
        ),
        subdomainLinkToSystem: new UntypedFormControl(
          subdomain ? subdomain.subdomainLinkToSystem : "",
          [Validators.required, Validators.pattern("^((?!\\s).)*$")],
        ),
        proxy: new UntypedFormControl(subdomain ? subdomain.proxy : ""),
        defaultSubShopId: new UntypedFormControl(
          subdomain ? subdomain.defaultSubShopId : "",
        ),
        languageCode: new UntypedFormControl(
          subdomain ? subdomain.languageCode : "de",
        ),
      }),
    );
    this.accountSystemSubDomains.push(subdomainFormGroup);
  }

  public selectSubdomain(subDomainMain: AbstractControl) {
    for (const subDomain of this.accountSystemSubDomains.controls) {
      subDomain.get("mainSubDomain").patchValue(false);
    }
    subDomainMain.get("mainSubDomain").patchValue(true);
  }

  deleteSubDomain(index: number) {
    this.accountSystemSubDomains.removeAt(index);
  }

  public isInstallationStatus(
    installationStatus: InstallationStatusEnum,
  ): boolean {
    if (!this.accountSystemDiscr) {
      return false;
    }
    switch (installationStatus) {
      case InstallationStatusEnum.added:
        return (
          !this.accountSystem ||
          this.accountSystem?.installationStatus ===
            InstallationStatusEnum.added
        );
      case InstallationStatusEnum.installed:
        return (
          this.accountSystem?.installationStatus ===
          InstallationStatusEnum.installed
        );
      case InstallationStatusEnum.active:
        return (
          this.accountSystem?.installationStatus ===
          InstallationStatusEnum.active
        );
      case InstallationStatusEnum.productive:
        return (
          this.accountSystem?.installationStatus ===
          InstallationStatusEnum.productive
        );
    }
  }

  get accountSystemSubDomains() {
    return this.formGroup.controls[
      "accountSystemSubDomains"
    ] as UntypedFormArray;
  }

  get accountSystemDiscr() {
    return this.formGroup.get("discr").value;
  }

  get languageCodeSI() {
    return Constants.getLanguageOptions();
  }
}
