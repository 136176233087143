<p-calendar
  showIcon="true" showButtonBar="true" appendTo="body" dateFormat="dd.mm.yy"
  [formControl]="form.get('filter')"
  [placeholder]="config.header"
  [pTooltip]="config.tooltip"
  tooltipPosition="bottom"
  [selectionMode]="selectionMode"
  [yearRange]="yearRangeString">
</p-calendar>




