import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
} from "@angular/core";
import {
  BarcodeFormat,
  BrowserMultiFormatReader,
  IScannerControls,
} from "@zxing/browser";
import { Subject } from "rxjs";
import { OverlayHandle } from "@incert/incert-core";
import { LoadingWrapperComponent } from "../loading-wrapper/loading-wrapper.component";
import { DecodeHintType } from "@zxing/library";
import { INCERT_GUI_I18N, IncertGUII18n } from "../incert-gui-i18n.token";

@Component({
  selector: "incert-barcode-scanner",
  templateUrl: "./barcode-scanner.component.html",
  styleUrls: ["./barcode-scanner.component.css"],
})
export class BarcodeScannerComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  public error = false;

  @ViewChild(LoadingWrapperComponent)
  private loadingWrapper: LoadingWrapperComponent;

  @ViewChild("video", { read: ElementRef })
  private videoElementRef: ElementRef;

  private _scanResult$ = new Subject<string>();

  public get scanResult$() {
    return this.scanResult$.asObservable();
  }

  private controls: IScannerControls;

  constructor(
    @Optional() private overlayHandle: OverlayHandle,
    @Inject(INCERT_GUI_I18N) public incertGuiI18n: IncertGUII18n,
  ) {}

  ngOnInit(): void {}

  async ngAfterViewInit() {
    let stream: MediaStream = null;
    // dont wait for full-fill
    await this.loadingWrapper.loadingService.load(async () => {
      const constraints: MediaStreamConstraints = {
        video: {
          facingMode: { ideal: "environment" },
        },
      };
      if (navigator.mediaDevices.getSupportedConstraints()["focusMode"]) {
        constraints.video["focusMode"] = { ideal: "continuous" };
      }

      stream = await navigator.mediaDevices.getUserMedia(constraints);
    });

    if (!stream || !stream.getVideoTracks().length) {
      this.error = true;
      return;
    }

    try {
      console.log(stream.getVideoTracks()[0].getCapabilities());
      console.log(stream.getVideoTracks()[0].getConstraints());
      await stream.getVideoTracks()[0].applyConstraints({
        advanced: [
          {
            // @ts-ignore
            focusMode: "continuous",
          },
        ],
      });
    } catch (e) {
      console.error(e);
    }

    const hints = new Map();
    const formats = [
      BarcodeFormat.CODE_128,
      BarcodeFormat.AZTEC,
      BarcodeFormat.DATA_MATRIX,
      BarcodeFormat.QR_CODE,
    ];
    hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);
    hints.set(DecodeHintType.TRY_HARDER, true);
    const codeReader = new BrowserMultiFormatReader(hints);
    try {
      this.controls = await codeReader.decodeFromStream(
        stream,
        this.videoElementRef.nativeElement,
        (result, error, controls) => {
          if (typeof controls.switchTorch === "function") {
            controls.switchTorch(true);
          }
          if (result) {
            this._scanResult$.next(result.getText());
            if (this.overlayHandle) {
              this.overlayHandle.close(result.getText());
            }
          }
        },
      );
    } catch (e) {
      console.error(e);
    }
  }

  ngOnDestroy(): void {
    if (this.controls) {
      this.controls.stop();
    }
  }
}
