import { DataTableSubdomainComponent } from "./data-table-subdomain/data-table-subdomain.component";
import { DataTablePermissionCheckbox } from "./data-table-checkbox/permission-checkbox.component";
import { DataTableCommentAreaComponent } from "./data-table-comment-area/data-table-comment-area.component";
import { DataTableLinkComponent } from "./data-table-link/data-table-link.component";
import { DataTableGlobalSearchComponent } from "./data-table-global-search/data-table-global-search.component";
import { UrlSubdomainPipe } from "./data-table-subdomain/url-subdomain.pipe";
import { ShortenPipe } from "./shorten.pipe";
import { CustomerListPrimeModule } from "../../customer-list-prime.module";
import { NgModule } from "@angular/core";
import { CustomerListSharedModule } from "../../customer-list-shared.module";
import { DataTableTagComponent } from "./data-table-tag/data-table-tag.component";
import { IncertTagModule } from "@incert/incert-gui";

@NgModule({
  imports: [CustomerListSharedModule, CustomerListPrimeModule, IncertTagModule],
  declarations: [
    DataTableGlobalSearchComponent,
    DataTableSubdomainComponent,
    DataTablePermissionCheckbox,
    DataTableCommentAreaComponent,
    DataTableLinkComponent,
    UrlSubdomainPipe,
    ShortenPipe,
    DataTableTagComponent,
  ],
  exports: [
    DataTableGlobalSearchComponent,
    DataTableSubdomainComponent,
    DataTablePermissionCheckbox,
    DataTableCommentAreaComponent,
    DataTableLinkComponent,
    ShortenPipe,
    DataTableTagComponent,
  ],
})
export class CustomerListDataTableModule {}
