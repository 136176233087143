<form [formGroup]='formGroup'>
  <form-field label="Veröffentlicht">
    <p-inputSwitch
      formControlName='published'>
    </p-inputSwitch>
  </form-field>
  <form-field label="Marketplace Typen">
    <p-multiSelect
      [options]="marketplaceTypeSI"
      appendTo="body"
      formControlName="types"
      placeholder="Marketplace Typen">
    </p-multiSelect>
  </form-field>
  <form-field label="Access Profile">
    <p-multiSelect
      [options]="accessProfileSI"
      appendTo="body"
      formControlName="accessProfiles"
      placeholder="Access Profile">
    </p-multiSelect>
  </form-field>
  <p-tabView formArrayName="translations">
    <ng-container *ngFor="let translation of translations">
      <p-tabPanel [header]="getLanguageHeader(translation.value.language)" [formGroup]="translation">
        <ng-container formGroupName="description">
          <form-field label="Bezeichnung">
            <input placeholder="Bezeichnung" type="text"
                   formControlName="name"/>
          </form-field>
        </ng-container>
        <ng-container formArrayName="resources"
                      *ngFor="let resource of getResources(translation); let i = index">
          <div class="box-entry separator-bottom" [formGroup]="resource">
            <div class="box-entry-content grid p-align-center">
              <form-field class="col-4" label="Ressourcen Id">
                <p-dropdown [options]="resourceIdSI" formControlName="resourceId"
                            placeholder="Ressourcen Id" (onChange)="setResourceType(resource)"></p-dropdown>
              </form-field>
              <form-field *ngIf="isResourceType(resource, 'text')" class="col-7" [label]=getResourceLabel(resource)>
                <input [placeholder]=getResourceLabel(resource) type="text"
                       formControlName="resourceData"/>
              </form-field>
              <ng-container *ngIf="isResourceType(resource, 'file')">
                <ng-container *ngIf="resource?.value?.resourceData">
                  <form-field class="col-7" [label]=getResourceLabel(resource)>
                    <icon-button fullWidth="true" buttonType="secondary" [label]="resource.value.resourceData" (click)="openResource(resource.value.resourceData)"></icon-button>
                  </form-field>
                </ng-container>
                <ng-container *ngIf="!resource?.value?.resourceData">
                  <form-field class="col-7" [label]=getResourceLabel(resource)>
                    <inc-file-upload
                      [maxNumberOfFiles]="1"
                      [fileType]="'/*'"
                      formControlName="fileData">
                    </inc-file-upload>
                  </form-field>
                </ng-container>
              </ng-container>
              <div class="col-1 align-right">
                <icon-button iconOnly='true' iconType="close" (click)="deleteResource(resource, translation, i)"
                             displayIconButtonAsButton="true">
                </icon-button>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="align-right">
          <icon-button iconType="plus" (click)="addResource(translation)" label="Ressource"></icon-button>
        </div>
      </p-tabPanel>
    </ng-container>
  </p-tabView>
</form>
