import { Component, Inject } from "@angular/core";
import { IAuthManager } from "../../auth";
import { Router } from "@angular/router";
import { RoutesDef } from "../../../app-routing.module";
import { DiscrEnum } from "../../enums/discr.enum";
import { IconType } from "@incert/incert-core";

@Component({
  selector: "sac-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent {
  isLoginAs: number;
  userName: string;
  DiscrEnum = DiscrEnum;
  RouteDef = RoutesDef;

  constructor(
    @Inject("AuthManager") private authManager: IAuthManager,
    private router: Router,
  ) {}

  public get isLoggedIn(): boolean {
    return this.authManager.isUserLoggedIn;
  }

  public get isFirstLogin(): boolean {
    if (this.authManager.authModel != null) {
      return this.authManager.authModel.firstLogin === 0;
    } else {
      return false;
    }
  }

  public hasUserName() {
    this.userName = localStorage.getItem("USER_NAME");
    if (this.userName !== "" && this.userName !== null) {
      return true;
    }
  }

  public logout(): void {
    this.authManager.logout();
  }

  public relogin() {
    this.authManager.relogin(this.isLoginAs);
  }

  public async navigateHome() {
    await this.router.navigate(["/account-system-overview"]);
  }

  async navigate(route: keyof typeof RoutesDef) {
    await this.router.navigate(["/" + route]);
  }

  public isAdminLoggedIn() {
    this.isLoginAs = parseInt(localStorage.getItem("ADMIN_LOGGED_IN"), 10);
    return this.isLoginAs > 0 && !isNaN(this.isLoginAs);
  }

  public showNavbar() {
    this.userName = localStorage.getItem("USER_NAME");
    this.isLoginAs = parseInt(localStorage.getItem("ADMIN_LOGGED_IN"), 10);
    if (this.isLoggedIn) {
      if (!this.isFirstLogin) {
        return true;
      } else {
        if (this.isLoginAs > 0 && !isNaN(this.isLoginAs)) {
          return true;
        }
      }
    }
    return false;
  }

  public get readAccount(): boolean {
    return this.authManager.hasPermission("readAccount");
  }

  public get readSystem(): boolean {
    return this.authManager.hasPermission("readSystem");
  }

  public get readSystemGroup(): boolean {
    return this.authManager.hasPermission("readSystemGroup");
  }

  public get readUser(): boolean {
    return this.authManager.hasPermission("readUser");
  }

  public get readRole(): boolean {
    return this.authManager.hasPermission("readRole");
  }

  public get readInfo(): boolean {
    return this.authManager.hasPermission("readInfo");
  }

  public get readLoginProtocol(): boolean {
    return this.authManager.hasPermission("readLoginProtocol");
  }

  public get readUserProtocol(): boolean {
    return this.authManager.hasPermission("readUserProtocol");
  }

  public get readInstallationProtocol(): boolean {
    return this.authManager.hasPermission("readInstallationProtocol");
  }

  public get readAccessProtocol(): boolean {
    return this.authManager.hasPermission("readAccessProtocol");
  }

  public get readRunnerProtocol(): boolean {
    return this.authManager.hasPermission("readRunnerProtocol");
  }

  public get readMemo(): boolean {
    return this.authManager.hasPermission("readMemo");
  }

  public get readNotification(): boolean {
    return this.authManager.hasPermission("readNotification");
  }

  public get readSystemValue(): boolean {
    return this.authManager.hasPermission("readSystemValue");
  }

  public get readContact(): boolean {
    return this.authManager.hasPermission("readContact");
  }

  public get readContactGroup(): boolean {
    return this.authManager.hasPermission("readContactGroup");
  }

  public get readAccessProfile(): boolean {
    return this.authManager.hasPermission("readAccessProfile");
  }

  public get readMarketplace(): boolean {
    return this.authManager.hasPermission("readMarketplace");
  }

  protected readonly IconType = IconType;
}
