<p><b>accessProfile</b></p>
<div>Welche Access Profile sind hinterlegt</div>
<p><b>articleGroups</b></p>
<div>Welche Artikelgruppen sind hinterlegt</div>
<p><b>cluster</b></p>
<div>Auf welchem Cluster liegt das System</div>
<p><b>consentManager</b></p>
<div>Ist ein Consent Manager hinterlegt</div>
<p><b>country</b></p>
<div>Welches Land ist hinterlegt</div>
<p><b>coupons</b></p>
<div>Welche Coupons sind hinterlegt</div>
<p><b>custPermission</b></p>
<div>Welche Cust Permissions sind hinterlegt</div>
<p><b>dbServer</b></p>
<div>Auf welchem Server liegt das System</div>
<p><b>emailTransport</b></p>
<div>Welcher Email-Transport ist hinterlegt</div>
<p><b>extensions</b></p>
<div>Welche Extensions sind hinterlegt</div>
<p><b>industry</b></p>
<div>Welche Industrie ist hinterlegt</div>
<p><b>installationStatus</b></p>
<div>Auf welchem Status befindet sich das System (productive = eingeschalten)</div>
<p><b>interfaceTypes</b></p>
<div>Welche Interfaces sind für das System hinterlegt</div>
<p><b>isDev</b></p>
<div>Ist das System für die Entwicklung oder Live</div>
<p><b>payment</b></p>
<div>Welche Zahlungsmodule sind aktiv</div>
<p><b>portalName</b></p>
<div>Auf welchem Server liegt das Sytem</div>
<p><b>pseudo</b></p>
<div>Werden Daten pseudonymisiert</div>
<p><b>ptlWidget</b></p>
<div>Ist Ptl Widget aktiv</div>
<p><b>publicApi</b></p>
<div>Ist Public Api aktiv</div>
<p><b>reservationSystem</b></p>
<div>Ist das System ein Reservierungssystem</div>
<p><b>rksv</b></p>
<div>Ist RKSV hinterlegt</div>
<p><b>subShops</b></p>
<div>Welche Subshops sind hinterlegt</div>
<p><b>systemType</b></p>
<div>Ist das System professional, express oder LOY</div>
<p><b>tracking</b></p>
<div>Ist Tracking im System aktiv</div>
<p><b>version</b></p>
<div>Welche Frontend-Version ist hinterlegt</div>
