import { Injectable } from "@angular/core";
import { APIService } from "../../../core/api.service";
import { HttpService } from "../../../core";
import { Marketplace, MarketplaceType } from "./marketplace.model";
import { SelectItem } from "primeng/api";
import { AuthConstants } from "../../../core/auth";

@Injectable({
  providedIn: "root",
})
export class MarketplaceService {
  constructor(
    private httpService: HttpService,
    private settingsService: APIService,
  ) {}

  async getMarketplaces() {
    return await this.httpService.get<Marketplace[]>(
      this.settingsService.baseUrl + "marketplace",
    );
  }

  async getMarketplaceTypesSI(): Promise<SelectItem[]> {
    const marketplaceTypes = await this.httpService.get<MarketplaceType[]>(
      this.settingsService.baseUrl + "marketplace/type",
    );
    return marketplaceTypes.map((v) => {
      return {
        label: v.name,
        value: v.id,
      };
    });
  }

  async upsertMarketplace(marketplace: Marketplace) {
    return await this.httpService.post<any>(
      this.settingsService.baseUrl + "marketplace",
      marketplace,
    );
  }

  public async deleteMarketplace(marketplaceId: number) {
    return await this.httpService.delete<any>(
      this.settingsService.baseUrl + "marketplace/" + marketplaceId,
    );
  }

  async uploadFile(file) {
    let res: any;
    try {
      res = await this.httpService.post<any>(
        this.settingsService.mediaLibraryUrl,
        new Blob([file], { type: file.type }),
        {
          headers: {
            Authorization: localStorage.getItem(AuthConstants.RESOURCES_TOKEN),
            PartId: "global",
          },
        },
      );
      return res.objectKey + "." + res.ext;
    } catch (e) {
      return false;
    }
  }

  async deleteFile(fileName: string) {
    try {
      await this.httpService.delete<any>(
        this.settingsService.mediaLibraryUrl + "/" + fileName,
        {
          headers: {
            Authorization: localStorage.getItem(AuthConstants.RESOURCES_TOKEN),
            PartId: "global",
          },
        },
      );
    } catch (e) {
      return false;
    }
  }
}
