<div *ngIf='accountForm'>
  <form [formGroup]='accountForm' class='grid'>
    <div class='col-12'>
      <form-field label='Name'>
        <input pInputText type='text' name='accountName' formControlName='name' placeholder="Name">
      </form-field>
    </div>
    <div class='col-6'>
      <form-field label='Zip Code'>
        <input pInputText type='text' name='zipCode' formControlName='zipCode' placeholder="Zip Code">
      </form-field>
    </div>
    <div class='col-6'>
      <form-field label='Land'>
        <p-dropdown [options]='countries' formControlName='country' placeholder='Land'
                    optionLabel='name' appendTo='body'></p-dropdown>
      </form-field>
    </div>
    <div class='col-6'>
      <form-field label='Sprache'>
        <p-dropdown [options]='languages' formControlName='language' placeholder='Sprache'
                    optionLabel='name' appendTo='body'></p-dropdown>
      </form-field>
    </div>
    <div class='col-6'>
      <form-field label='Währung'>
        <p-dropdown [options]='currencies' formControlName='currency'
                    placeholder='Währung'
                    optionLabel='name' appendTo='body'></p-dropdown>
      </form-field>
    </div>
    <div class='col-12'>
      <form-field label="E-Mail(s)">
        <input
          #f
          pTooltip='Emails können mit Semikolon ; getrennt werden'
          tooltipPosition='top'
          pInputText
          type='text'
          placeholder='E-Mail Adressen'
          (keyup)='onNewEmailEntered($event, f)'
          (blur)='onCheckSingleEmail(f)'>
      </form-field>
    </div>
    <div>
      <div *ngFor='let email of emails; let i = index'>
        <icon-button
          class='email-btn'
          (click)='deleteEmail(i, email.id)'
          iconType='error'
          label='{{email.email}}'
          pTooltip='Klicken, um zu entfernen'
          tooltipPosition='bottom'></icon-button>
      </div>
    </div>
  </form>
</div>
