import { Component, OnInit } from "@angular/core";
import { createNameof, NotificationService } from "@incert/incert-core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { markAsDirty, modelToForm } from "@incert/incert-gui";
import {
  AccountSystemFilter,
  AccountSystemGroup,
} from "../account-system-group";
import { AccountSystemManager } from "../../account-system/account-system-manager.service";
import { AccountSystemGroupManager } from "../account-system-group-manager.service";

@Component({
  selector: "incert-account-system-group-upsert",
  templateUrl: "./account-system-group-upsert.component.html",
  styleUrls: ["./account-system-group-upsert.component.css"],
})
export class AccountSystemGroupUpsertComponent implements OnInit {
  nameofForm = createNameof<AccountSystemGroup>();
  formGroup: UntypedFormGroup;
  accountSystemSI: any[] = [];
  accountSystemExcludedSI: any[] = [];
  accountSystemFilters: AccountSystemFilter[] = [];
  systemGroup: AccountSystemGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private accountSystemManager: AccountSystemManager,
    private accountSystemGroupManager: AccountSystemGroupManager,
    private notificationService: NotificationService,
  ) {
    this.formGroup = this.fb.group(
      modelToForm<AccountSystemGroup>({
        id: [""],
        name: ["", [Validators.required]],
        accountSystemFilter: [""],
        edit: [false],
        accountSystems: [{ value: "", disabled: true }, [Validators.required]],
      }),
    );
  }

  async ngOnInit() {
    if (this.systemGroup) {
      this.formGroup.patchValue(this.systemGroup);
      this.formGroup
        .get("accountSystemFilter")
        .patchValue(this.systemGroup?.accountSystemFilter?.id);
      this.accountSystemExcludedSI =
        await this.accountSystemManager.getAccountSystemsExcludedInGroupSI(
          this.systemGroup.id,
        );
    }
    await this.updateFilter();
  }

  async initFilter() {
    const included = this.accountSystemSI
      .filter((v) => {
        let found = false;
        for (const e of this.accountSystemExcludedSI) {
          if (e.value === v.value) {
            found = true;
          }
        }
        return !found;
      })
      .map((v) => {
        return v.value;
      });
    this.formGroup.get("accountSystems").patchValue(included);
    if (
      this.accountSystemSI.length ===
        this.formGroup.get("accountSystems").value.length ||
      this.formGroup.get("accountSystems").value.length <= 0
    ) {
      this.toggleSystemEdit(false);
    } else {
      this.toggleSystemEdit(true);
    }
  }

  async updateFilter() {
    this.accountSystemSI =
      await this.accountSystemManager.getAccountSystemsByFilterSI(
        this.formGroup.get("accountSystemFilter").value,
      );
    this.formGroup.get("accountSystems").patchValue([]);
    await this.initFilter();
  }

  async upsertSystemGroup() {
    markAsDirty(this.formGroup);
    if (this.formGroup.valid) {
      const accountSystemGroup: AccountSystemGroup = this.formGroup.value;
      accountSystemGroup.accountSystemsExcluded = this.accountSystemSI
        .filter((v) => {
          let found = false;
          for (const a of this.formGroup.get("accountSystems").value) {
            if (v.value === a) {
              found = true;
            }
          }
          return !found;
        })
        .map((v) => {
          return {
            id: v.value,
          };
        });
      accountSystemGroup.accountSystemFilter = {
        id: this.formGroup.get("accountSystemFilter").value,
      };
      await this.accountSystemGroupManager.upsertAccountSystemGroup(
        accountSystemGroup,
      );
      this.notificationService.notifySuccess(
        this.systemGroup
          ? "Kontaktgruppe bearbeitet"
          : "Kontaktgruppe hinzugefügt",
      );
      return true;
    }
    return false;
  }

  toggleSystemEdit(toggle: boolean) {
    if (toggle) {
      this.formGroup.get("accountSystems").enable();
      this.formGroup.get("edit").patchValue(toggle);
    } else {
      this.formGroup.get("accountSystems").disable();
      this.formGroup.get("edit").patchValue(toggle);
    }
  }
}
