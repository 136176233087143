import { Component, OnInit } from "@angular/core";
import { NotificationManagerService } from "./notification-manager.service";
import {
  ArrayDataSource,
  createComponentConfiguration,
  createFilterDefinition,
  DataTableConfig,
  DataTableIconButtonsComponent,
  DateFilterComponent,
  SelectFilterComponent,
  TextFilterComponent,
} from "@incert/incert-gui";
import {
  Notification,
  NotificationLanguage,
  NotificationPermission,
} from "./notification.interface";
import {
  LoadingService,
  NotificationService,
  OverlayService,
  OverlaySize,
} from "@incert/incert-core";
import { AddNotificationComponent } from "./add-notification/add-notification.component";
import { AccountSystem } from "../../../core/models/account-system.interface";
import { AccountSystemManager } from "../../account-system/account-system-manager.service";
import { AuthManager } from "../../../core/auth";
import { Utils } from "../../../core/utils";
import { SelectItem } from "primeng/api";

@Component({
  selector: "incert-internal-tools-notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.scss"],
})
export class NotificationComponent implements OnInit {
  public notifications: Array<Notification>;
  public notificationPermissions: Array<NotificationPermission>;
  public notificationLanguages: Array<NotificationLanguage>;
  public notificationConfig: DataTableConfig<Notification>;
  public notificationBehaviorsSI: Array<SelectItem>;
  public notificationCategoriesSI: Array<SelectItem>;
  public accountSystems: Array<AccountSystem>;
  public arrayDataSource: ArrayDataSource<Notification>;
  public searchString: string;

  constructor(
    private notificationManager: NotificationManagerService,
    private accountSystemManager: AccountSystemManager,
    private loadingService: LoadingService,
    private overlayService: OverlayService,
    private notificationService: NotificationService,
    private authManager: AuthManager,
  ) {}

  async ngOnInit() {
    await this.initialize();
  }

  private async initialize() {
    await this.loadingService.load(async () => {
      this.notifications = await this.notificationManager.getNotifications();
      this.notificationCategoriesSI =
        await this.notificationManager.getNotificationCategories();
      this.notificationPermissions =
        await this.notificationManager.getNotificationPermissions();
      this.notificationBehaviorsSI =
        await this.notificationManager.getNotificationBehaviors();
      this.notificationLanguages =
        await this.notificationManager.getNotificationLanguages();
      this.accountSystems =
        await this.accountSystemManager.getStandaloneAccountSystems();
      this.createDtTable();
    });
  }

  private createDtTable() {
    for (const notification of this.notifications) {
      notification.accountSystemsStr = notification.accountSystems
        .map((v) => v.short)
        .join(", ");
      notification.translationsStr = notification.translations
        .map((v) => v.language)
        .join(", ");
      notification.permissionsStr = notification.permissions
        .map((v) => v.name)
        .join(", ");
    }
    this.arrayDataSource = new ArrayDataSource<Notification>(
      this.notifications,
    );
    this.notificationConfig = {
      data: this.arrayDataSource,
      rows: 30,
      columnEdit: true,
      mode: "pagination",
      filterArea: {
        filters: [
          {
            header: "Titel",
            property: (v) => v.get("translations", 0, "title"),
            filter: createFilterDefinition(TextFilterComponent, {
              property: (v) => v.get("translations", 0, "title"),
            }),
          },
          {
            header: "Kurzbeschreibung",
            property: (v) => v.get("translations", 0, "shortDescription"),
            filter: createFilterDefinition(TextFilterComponent, {
              property: (v) => v.get("translations", 0, "shortDescription"),
            }),
          },
          {
            header: "Kundensystem",
            property: (v) => v.get("accountSystemsStr"),
            filter: createFilterDefinition(TextFilterComponent, {
              property: (v) => v.get("accountSystemsStr"),
            }),
          },
          {
            header: "Kategorie",
            property: (v) => v.get("category", "name"),
            filter: createFilterDefinition(SelectFilterComponent, {
              property: (v) => v.get("category", "severity"),
              options: this.notificationCategoriesSI,
            }),
          },
          {
            header: "Verhalten",
            property: (v) => v.get("behavior"),
            filter: createFilterDefinition(SelectFilterComponent, {
              property: (v) => v.get("behavior"),
              options: this.notificationBehaviorsSI,
            }),
          },
          {
            header: "Aktiv ab",
            property: (v) => v.get("startDate"),
            filter: createFilterDefinition(DateFilterComponent, {
              type: "between",
              property: (v) => v.get("startDate"),
            }),
          },
          {
            header: "Endet am",
            property: (v) => v.get("endDate"),
            filter: createFilterDefinition(DateFilterComponent, {
              type: "between",
              property: (v) => v.get("endDate"),
            }),
          },
        ],
      },
      columns: [
        {
          header: "Titel",
          property: (v) => v.get("translations", 0, "title"),
        },
        {
          header: "Kurzbeschreibung",
          property: (v) => v.get("translations", 0, "shortDescription"),
        },
        {
          header: "Beschreibung",
          property: (v) => v.get("translations", 0, "text"),
          hidden: true,
        },
        {
          header: "Kundensystem",
          property: (v) => v.get("accountSystemsStr"),
          transform: (v) => {
            const accountSystems = v.split(",", 5);
            if (accountSystems.length === 5) {
              return accountSystems + ", etc.";
            }
            return accountSystems;
          },
        },
        {
          header: "Kategorie",
          property: (v) => v.get("category", "name"),
          sort: true,
        },
        {
          header: "Verhalten",
          property: (v) => v.get("behavior"),
          sort: true,
        },
        {
          header: "Aktiv ab",
          property: (v) => v.get("startDate"),
          transform: (v) => (v ? Utils.getFormattedDateTime(v) : ""),
          sort: true,
        },
        {
          header: "Endet am",
          property: (v) => v.get("endDate"),
          transform: (v) => (v ? Utils.getFormattedDateTime(v) : ""),
          sort: true,
        },
        {
          header: "Aktionen",
          hidden: !this.writeNotification,
          property: (v) => v.get("id"),
          component: createComponentConfiguration(
            DataTableIconButtonsComponent,
            {
              iconConfig: [
                {
                  icon: "edit",
                  hidden: !this.writeNotification,
                  onClick: (row: Notification) =>
                    this.showAddNotificationOverlay(true, row),
                  tooltip: "Bearbeiten",
                },
                {
                  icon: "delete",
                  hidden: !this.executeNotification,
                  onClick: (row: Notification) => this.deleteNotification(row),
                  tooltip: "Löschen",
                },
              ],
            },
          ),
        },
      ],
      additionalHeaderComponents: [
        createComponentConfiguration(DataTableIconButtonsComponent, {
          iconConfig: [
            {
              icon: "plus",
              label: "Notification",
              onClick: () => this.showAddNotificationOverlay(false),
              onShow: () => this.writeNotification,
            },
          ],
        }),
      ],
    };
  }

  private deleteNotification(notification: Notification) {
    this.notificationManager.deleteNotification(notification.id).then(() => {
      this.arrayDataSource.reloadDataTable(
        this.arrayDataSource.data.filter((n) => n.id !== notification.id),
      );
      this.notificationService.notifySuccess(
        "Die Notification wurde erfolgreich gelöscht",
        "Notification gelöscht",
      );
    });
  }

  public async showAddNotificationOverlay(
    isEditMode: boolean,
    notification?: Notification,
  ) {
    await this.overlayService.show<AddNotificationComponent>({
      type: AddNotificationComponent,
      header: isEditMode ? "Notification bearbeiten" : "Notification erstellen",
      displayAsSidebar: true,
      init: (c) => {
        c.notificationPermissions = this.notificationPermissions;
        c.notificationBehaviors = this.notificationBehaviorsSI;
        c.notificationCategoriesSI = this.notificationCategoriesSI;
        c.notificationLanguages = this.notificationLanguages;
        c.accountSystems = this.accountSystems;
        c.editNotification = notification;
      },
      size: OverlaySize.large,
      actions: [
        {
          buttonType: "primary",
          label: "Speichern",
          action: (component) => {
            return component
              .upsertNotification()
              .then((res) => (res ? this.initialize() && true : false));
          },
        },
        {
          displayAsLink: true,
          label: "Abbrechen",
          action: () => true,
        },
      ],
    });
  }

  public get writeNotification(): boolean {
    return this.authManager.hasPermission("writeNotification");
  }

  public get executeNotification(): boolean {
    return this.authManager.hasPermission("executeNotification");
  }
}
