import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Category, Memo, Tag } from "../memo.interface";
import { MemoManager } from "../memo.manager.service";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { NotificationService } from "@incert/incert-core";
import { Subscription } from "rxjs";
import { SelectItem } from "primeng/api";
import { ActionInterface } from "../../../account-system/account-system-action/account-system-action.interface";
import { AccountSystem } from "../../../../core/models/account-system.interface";
import { User, UserManager } from "../../../user";
import { AuthManager } from "../../../../core/auth";
import { Utils } from "../../../../core/utils";
import { Editor } from "primeng/editor";

@Component({
  selector: "incert-internal-tools-add-new-memo",
  templateUrl: "./memo-add.component.html",
  styleUrls: ["./memo-add.component.scss"],
})
export class MemoAdd implements OnInit, OnDestroy, ActionInterface {
  private MAX_COMMENT_LENGTH = 1511;

  @ViewChild("editor") editor: Editor;

  public accountSystems: Array<AccountSystem> = [];
  public categories: Array<Category> = [];
  public onDataChanged: Subscription;
  public usersDropdown: SelectItem[];
  public accountSystemDropdown: SelectItem[];
  public activeUser: User;
  public formGroup: UntypedFormGroup;
  public users: Array<User>;
  public tags: Array<Tag> = [];
  public memo: Memo = null;
  public lettersLeft: number;

  constructor(
    private authManager: AuthManager,
    private memoManager: MemoManager,
    private userManager: UserManager,
    private notificationService: NotificationService,
  ) {
    this.onDataChanged = this.memoManager.dataChanged.subscribe(async () => {
      await this.getMemoRelatedData();
    });
    this.createFormGroup();
  }

  public async onAction(data: AccountSystem[], selectedData: AccountSystem[]) {
    this.accountSystems = data;
    this.users = await this.userManager.getUsers();
    this.tags = await this.memoManager.getMemoTags();
    this.categories = await this.memoManager.getMemoCategories();

    const preSelected = this.accountSystems.filter(
      (a) => selectedData.map((d) => d.id).indexOf(a.id) !== -1,
    );
    this.formGroup.get("accountSystems").patchValue(preSelected);
  }

  async ngOnInit() {
    await this.getMemoRelatedData();
  }

  private async getMemoRelatedData() {
    await this.getActiveUser();
    this.getCustomDropDownFields();
  }

  private async getActiveUser() {
    try {
      this.activeUser = await this.userManager.getUser(
        this.authManager.getLoggedInUserId(),
      );
      this.formGroup.patchValue({ createdBy: this.activeUser });
    } catch (e) {
      this.notificationService.notifyError(
        "Aktiver Benutzer konnten nicht geladen werden",
        "Fehler beim Laden",
      );
    }
  }

  private getCustomDropDownFields() {
    this.usersDropdown = Utils.mapTwoFieldsToSelectItem(
      this.users,
      "firstName",
      "lastName",
    );
    this.accountSystemDropdown = this.accountSystems.map((acs) => ({
      label: acs.discr + " | " + acs.name + " | " + acs.short,
      value: acs,
    }));
  }

  private createFormGroup() {
    this.formGroup = new UntypedFormGroup({
      id: new UntypedFormControl(""),
      title: new UntypedFormControl("", Validators.required),
      category: new UntypedFormControl("", Validators.required),
      comment: new UntypedFormControl("", [
        Validators.required,
        Validators.maxLength(this.MAX_COMMENT_LENGTH),
      ]),
      tags: new UntypedFormControl("", Validators.required),
      speakTo: new UntypedFormControl("", Validators.required),
      accountSystems: new UntypedFormControl("", Validators.required),
      milestone: new UntypedFormControl(null),
      createdBy: new UntypedFormControl(""),
      validUntil: new UntypedFormControl(null),
      pinned: new UntypedFormControl(false),
      universal: new UntypedFormControl(false),
    });
    this.formGroup.get("comment").valueChanges.subscribe((v) => {
      if (v) {
        this.lettersLeft = this.MAX_COMMENT_LENGTH - v.length;
        if (this.lettersLeft <= 0) {
          this.lettersLeft = 0;
        }
      }
    });
  }

  private addAlertTagIfValidDateProvided(memo: Memo) {
    if (memo.validUntil) {
      const alertTag = this.tags.find((t) => t.name === "Alert");
      if (alertTag && !memo.tags.includes(alertTag)) {
        memo.tags.push(alertTag);
      }

      memo.pinned = true;
    }

    return memo;
  }

  private addMilestoneTagIfDateProvided(memo: Memo) {
    if (memo.milestone) {
      const milestoneTag = this.tags.find((t) => t.name === "Milestone");

      if (milestoneTag && !memo.tags.includes(milestoneTag)) {
        memo.tags.push(milestoneTag);
      }
    }

    return memo;
  }

  private clearAccountSystemValidatorIfUniversal() {
    if (this.formGroup.get("universal").value) {
      this.formGroup.get("accountSystems").clearValidators();
    } else {
      this.formGroup.get("accountSystems").setValidators(Validators.required);
    }
  }

  public async onSaveNewMemo() {
    this.clearAccountSystemValidatorIfUniversal();
    this.markInvalidFiels();

    if (this.formGroup.valid) {
      try {
        let memo: Memo = this.formGroup.value;

        memo = this.addAlertTagIfValidDateProvided(memo);
        memo = this.addMilestoneTagIfDateProvided(memo);

        await this.memoManager.saveMemo(memo);

        this.notificationService.notifySuccess(
          "Memo wurde erfolgreich gespeichert!",
          "Speichern erfolgreich",
        );

        this.memoManager.markChanged();
        return true;
      } catch (e) {
        this.notificationService.notifyError(
          e.error.message,
          "Notiz konnte nicht gespeichert werden",
        );
      }
    } else {
      this.notificationService.notifyInfo(
        "Mindestens ein Feld wurde nicht befüllt oder enthält ungültige Eingaben",
        "Ungültige Daten",
      );
    }

    return false;
  }

  public async onSaveEditedMemo() {
    this.clearAccountSystemValidatorIfUniversal();
    this.markInvalidFiels();

    if (this.formGroup.valid) {
      try {
        let memo: Memo = this.formGroup.value;

        memo = this.addAlertTagIfValidDateProvided(memo);
        memo = this.addMilestoneTagIfDateProvided(memo);

        await this.memoManager.updateMemo(memo);

        this.memoManager.markChanged();
        this.notificationService.notifySuccess(
          "Memo wurde erfolgreich aktualisiert!",
          "Aktualisieren erfolgreich",
        );
        return true;
      } catch (e) {
        this.notificationService.notifyError(
          e.error.message,
          "Notiz konnte nicht aktualisiert werden",
        );
      }
    } else {
      this.notificationService.notifyInfo(
        "Mindestens ein Feld wurde nicht befüllt oder enthält ungültige Eingaben",
        "Ungültige Daten",
      );
    }

    return false;
  }

  public async onEditMemo(memo: Memo) {
    this.memo = memo;

    // this.formGroup.addControl("comment", new FormControl(memo.comment, Validators.required));
    this.formGroup.patchValue(memo);

    this.formGroup.patchValue({
      category: memo.category,
      accountSystems: this.accountSystems.filter(
        (a) => memo.accountSystems.map((na) => na.id).indexOf(a.id) !== -1,
      ),
      speakTo: this.users.filter(
        (a) => memo.speakTo.map((na) => na.id).indexOf(a.id) !== -1,
      ),
      validUntil: memo.validUntil ? new Date(memo.validUntil) : null,
      milestone: memo.milestone ? new Date(memo.milestone) : null,
      comment: memo.comment,
    });

    this.disableFormControls(memo);
  }

  private disableFormControls(memo: Memo) {
    if (memo.universal) {
      this.formGroup.get("accountSystems").disable();
    }

    this.formGroup.get("category").disable();
  }

  public disableAccountSystems() {
    if (this.formGroup.get("universal").value) {
      this.formGroup.get("accountSystems").disable();
    } else {
      this.formGroup.get("accountSystems").enable();
    }
  }

  public isPastTimeSince() {
    if (this.isPastTimeMemo()) {
      return Utils.showTimeWithCustomText(this.memo.validUntil, "seit");
    }
  }

  public isPastTimeMemo() {
    return Utils.isInPast(this.memo.validUntil);
  }

  private markInvalidFiels() {
    this.formGroup = Utils.markInvalidFieldsAsDirty(this.formGroup);
  }

  ngOnDestroy(): void {
    this.onDataChanged.unsubscribe();
  }
}
