<div *ngIf='accountSystemsSelectedDS'>
  <div class="align-center separator-bottom"> Kundensysteme ausgewählt: <strong>{{accountSystemsSelectedDS.total}}</strong></div>

  <div *ngIf="showSystems">
    <div class="align-right separator-bottom">
      <icon-button
        *ngIf='accountSystemsSelectedDS.getData().length'
        iconType="edit"
        pTooltip='Auswahl bestätigen & zurück zu Aktionen'
        label='Bestätigen & zurück'
        (click)="toggleShowSystems()"
        buttonType="secondary">
      </icon-button>
      <icon-button
        iconType="delete"
        pTooltip='Aktuelle Auswahl zurücksetzen'
        [label]="'Auswahl zurücksetzen'"
        (click)="resetSelectedSystems()"
        buttonType="secondary">
      </icon-button>
    </div>
    <p-tabView>
      <p-tabPanel header="Ausgewählte Systeme">
          <data-table-global-search [arrayDataSource]="accountSystemsSelectedDS"></data-table-global-search>
          <data-table [config]="accountSystemsSelectedConfig"></data-table>
      </p-tabPanel>
      <p-tabPanel header="System hinzufügen">
          <data-table-global-search [arrayDataSource]="accountSystemsNotSelectedDS"></data-table-global-search>
          <data-table [config]="accountSystemsNotSelectedConfig"></data-table>
      </p-tabPanel>
    </p-tabView>
  </div>
  <div *ngIf="!showSystems" class="grid">
    <div class="col-6" *ngFor="let action of actionConfig">
      <div class="account-system-action-card" [ngStyle]="{'backgroundColor': action.backgroundColor ? action.backgroundColor : ''}"
           (click)="action.action(); $event.stopPropagation()">
        <div class="account-system-action-card-icon">
          <incert-icon [iconType]="action.icon"></incert-icon>
        </div>
        <div class="account-system-action-card-inner">
          <h2>{{action.name}}</h2>
          <p>{{action.description}}</p>
        </div>
        <div class="account-system-action-card-actions">
          <ng-container *ngFor="let subAction of action.subAction">
            <icon-button
              displayIconButtonAsButton="true"
              tooltipPosition="top"
              iconOnly='true'
              [pTooltip]="subAction.tooltip ? subAction.tooltip : null"
              [iconType]="subAction.icon"
              (click)="$event.stopPropagation(); subAction.action();"></icon-button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
