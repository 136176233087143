import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  HostListener,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from "@angular/core";
import {
  getPropertyPath,
  IconType,
  nameof,
  OverlayService,
  OverlaySize,
} from "@incert/incert-core";
import { Table } from "primeng/table";
import {
  DataSource,
  DataSourceFilter,
  DataSourceSort,
  isDataSource,
  DataTableConfig,
  ColumnDefinition,
  FilterDefinition,
  isPropertyColumn,
  ProcessedRow,
  PropertyColumn,
  DataTableFilterComponent,
  FILTER_CONFIG,
} from "@incert/incert-gui";
import { DataTableColumnHelper } from "../shared/data-table-column.helper";
import { Subject, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { ArithmeticHandler } from "../data-table-aggregation/arithmetic-handler";
import {
  AggregateResult,
  ArithmeticResult,
} from "../data-table-aggregation/arithmetic-strategy";
import { I18nService } from "@incert/i18n";
import { DataTableExportOverlayComponent } from "../data-table-export-overlay/data-table-export-overlay.component";
import { DataTableColumnOverlayComponent } from "../data-table-column-overlay/data-table-column-overlay.component";
import { DataTablePersistence } from "../shared/interfaces/data-table-persistence.interface";

@Component({
  selector: "data-table-inner",
  templateUrl: "./data-table-inner.component.html",
  styleUrls: ["./data-table-inner.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataTableInnerComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input()
  public draggedElement: any;

  @Input()
  public config: DataTableConfig<any>;

  @ViewChild(Table, { static: true })
  table: Table;

  @ViewChildren("filterHost", { read: ViewContainerRef })
  public filterHosts = new QueryList<ViewContainerRef>();

  @ViewChild("filterSection", { read: ElementRef, static: false })
  public filterSectionElementRef: ElementRef = null;

  public columnHelper: DataTableColumnHelper;
  public selectedRows: any[];
  expandedRows: {} = {};
  public data: ProcessedRow[] = [];

  public IconType = IconType;

  // height of endless scroll
  public scrollHeight = "500px";
  public rowsPerPageOptions = null;

  // filter
  private filterComponentRefs: ComponentRef<DataTableFilterComponent>[] = [];
  public filterStates: (DataSourceFilter | null)[] = [];
  public aggregateResult: AggregateResult[] = [];
  public arithmeticHandler: ArithmeticHandler;
  public mobileView = null;
  public initialLoaded = false;
  persistence: DataTablePersistence = null;

  private scrollPage = 0;

  public get totalRows() {
    return this.config.data.total;
  }

  public load$ = new Subject<number>();
  private filterChangeSubscriptions: Subscription[] = [];
  private loadSubscription: Subscription;
  private refreshDataSourceSubscription: Subscription;
  private checkForChangesSubscription: Subscription;

  constructor(
    private e: ElementRef,
    private injector: Injector,
    private componentFactoryResolver: ComponentFactoryResolver,
    public cdr: ChangeDetectorRef,
    private overlayService: OverlayService,
    private i18n: I18nService,
  ) {}

  public refresh() {
    this.load$.next(undefined);
  }

  async ngOnInit() {
    await this.handlePersistence();
    this.createFilterViewRefs();
    this.setup();
  }

  setup() {
    this.columnHelper = new DataTableColumnHelper(
      this.config.columns,
      this.config,
    );
    if (this.config.data.refreshDatasource$ !== undefined) {
      this.refreshDataSourceSubscription =
        this.config.data.refreshDatasource$.subscribe(async () => {
          await this.load();
        });
    }

    if (this.config.aggregateData?.length) {
      this.arithmeticHandler = new ArithmeticHandler();
    }

    if (this.config.data.checkForChanges$ !== undefined) {
      this.checkForChangesSubscription =
        this.config.data.checkForChanges$.subscribe((v) => {
          if (v) this.cdr.markForCheck();
        });
    }

    if (this.config.mode === "pagination") {
      this.rowsPerPageOptions = [10, this.config.rows, 50];
    }

    //moved from ngAfterViewInit for correct sequence of events
    if (this.config.mode === "scroll") {
      const scrollableBodyElement = this.e.nativeElement.getElementsByClassName(
        "p-table-scrollable-body",
      )[0];
      if (scrollableBodyElement) {
        scrollableBodyElement.addEventListener("scroll", () => {
          if (
            Math.round(
              scrollableBodyElement.offsetHeight +
                scrollableBodyElement.scrollTop,
            ) === scrollableBodyElement.scrollHeight
          ) {
            if (isDataSource(this.config.data)) {
              this.scrollPage++;
              if (this.config.data.total !== this.data.length) {
                this.load$.next(this.scrollPage);
              }
            }
          }
        });
      }
    }

    // do at last => wait until filters are initialised
    this.loadSubscription = this.load$
      .pipe(debounceTime(200))
      .subscribe(async (v) => {
        await this.load(v);
      });

    this.load$.next(undefined);
  }

  ngAfterViewInit(): void {
    this.checkFilterSection();
  }

  @HostListener("window:resize", ["$event"])
  private onResize() {
    this.checkFilterSection();
  }

  @HostListener("window:focusin", ["$event"])
  private onFocusIn() {
    this.checkFilterSection();
  }

  @HostListener("window:click", ["$event"])
  private onWindowClick() {
    this.checkFilterSection();
  }

  public getPropertyPathJson(col: ColumnDefinition<any>): string {
    if (nameof<PropertyColumn<any>>("property") in col) {
      return JSON.stringify(
        getPropertyPath<any>((<PropertyColumn<any>>col).property),
      );
    }
    return "";
  }

  private async load(scrollPage?: number) {
    this.aggregateResult = [];

    if (!scrollPage && this.config.mode === "scroll") {
      this.scrollPage = 0;
      this.data = [];
    }
    this.table.loading = true;
    this.cdr.markForCheck();

    // map filter
    const filters = this.filterStates.filter((v) => v);
    // handle sort
    const sorts: DataSourceSort[] = [];
    if (this.table.multiSortMeta) {
      for (const meta of this.table.multiSortMeta) {
        const propertyPath = JSON.parse(meta.field);
        sorts.push({
          property: propertyPath,
          sortOrder: meta.order === -1 ? "desc" : "asc",
        });
      }
    }
    // load data
    const rows = await (<DataSource<any>>this.config.data).load({
      limit: this.table.rows,
      offset: scrollPage ? scrollPage * this.table.rows : this.table.first,
      sorts: sorts,
      filters: filters,
    });
    //  process data
    if (rows) {
      const data = this.columnHelper.processRows(rows);
      if (scrollPage) {
        data.forEach((v1) => this.data.push(v1));
      } else {
        this.data = data.map((row) => {
          // Reuse old processed row to prevent re-rendering of expanded component
          const obj = this.data.find((oldRow) => oldRow.key === row.key);
          if (!obj) {
            return row;
          }

          Object.assign(obj, row);
          return obj;
        });
      }
    }
    // get data aggregation
    if (this.config.aggregateData) {
      this.getAggregation();
    }

    if (
      this.totalRows === 1 &&
      (this.config.expansion?.singleRowExpanded ?? true)
    ) {
      this.expandedRows[this.data[0]?.key] = true;
      this.expandedRows = Object.assign({}, this.expandedRows);
    }
    this.table.loading = false;
    this.initialLoaded = true;
    this.cdr.markForCheck();
  }

  private getAggregation() {
    for (const column of this.columnHelper.flattedColumns) {
      if (column.property && !column.hidden) {
        let arithmeticResult: ArithmeticResult = null;
        for (const aggregation of this.config.aggregateData) {
          if (column.property.find((p) => p === aggregation.property)) {
            arithmeticResult = {
              value: this.arithmeticHandler
                .setArithmeticOperation(aggregation.arithmeticOperation)
                .calculate(this.config.data.data, aggregation.property),
              operation: aggregation.arithmeticOperation,
            };
          }
        }
        this.aggregateResult.push({
          property: column.property[0],
          result: arithmeticResult,
        });
      }
    }
  }

  private createFilterViewRefs() {
    let count = 0;
    for (const col of this.config.columns) {
      if (isPropertyColumn(col)) {
        if (col.filter) {
          //use col property if not provided
          if (!col.filter.config.property) {
            col.filter.config.property = col.property;
          }
          this.filterComponentRefs.push(
            this.createFilterComponent(col.filter, count),
          );
          count++;
        }
      }
    }
  }

  private createFilterComponent(filter: FilterDefinition<any>, count: number) {
    const injector = Injector.create({
      parent: this.injector,
      providers: [
        {
          provide: FILTER_CONFIG,
          useValue: filter.config,
        },
      ],
    });

    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(filter.component);
    const componentRef = componentFactory.create(injector);
    this.filterStates.push(null);
    const filterChangeSubscription = componentRef.instance.change
      .pipe(debounceTime(400))
      .subscribe(async (v) => {
        this.filterStates[count] = v;
        this.table.first = 0;
        this.load$.next(undefined);
      });

    this.filterChangeSubscriptions.push(filterChangeSubscription);
    return componentRef;
  }

  private checkFilterSection() {
    if (this.filterSectionElementRef) {
      // if filter Section not available
      const mobileView =
        this.filterSectionElementRef.nativeElement.clientHeight === 0;

      // put filter viewrefs into the right container
      if (this.mobileView !== mobileView) {
        if (mobileView) {
          this.filterHosts.toArray().forEach((v) => v.detach());
        } else {
          for (let i = 0; i < this.filterComponentRefs.length; i++) {
            this.filterHosts
              .toArray()
              [i].insert(this.filterComponentRefs[i].hostView);
            this.cdr.detectChanges();
          }
        }
      }
      this.mobileView = mobileView;
    }
  }

  ngOnDestroy(): void {
    if (this.loadSubscription) {
      this.loadSubscription.unsubscribe();
    }
    if (this.refreshDataSourceSubscription) {
      this.refreshDataSourceSubscription.unsubscribe();
    }
    this.filterChangeSubscriptions.forEach((v) => v.unsubscribe());
    if (this.checkForChangesSubscription) {
      this.checkForChangesSubscription.unsubscribe();
    }
  }

  isString(val: any): boolean {
    return typeof val === "string";
  }

  onRowReorder(dragDropIndexes: { dragIndex: number; dropIndex: number }) {
    if (this.config.dragDropList) {
      this.config.onRowReorder(
        this.data,
        dragDropIndexes.dragIndex,
        dragDropIndexes.dropIndex,
      );
    }
  }
  public getClassList() {
    let classList = this.config.class;
    if (this.config.displayAsNewIncList || this.config.dragDropList) {
      classList += " inc-dt-list";
    } else {
      classList += " inc-dt-classic";
    }
    if (this.config.resetSubtablesToRegularStyle) {
      classList += " reset-subtable-to-regular-style";
    }
    if (this.config.noHeader) {
      classList += " inc-dt-no-header";
    }
    return classList;
  }

  async overlayExport() {
    await this.overlayService.show<DataTableExportOverlayComponent>({
      type: DataTableExportOverlayComponent,
      size: OverlaySize.medium,
      displayAsSidebar: true,
      header: this.i18n.instant("core.dataTable.export"),
      init: (instance) => {
        instance.config = this.config;
        instance.persistence = this.persistence;
      },
      actions: [
        {
          label: this.i18n.instant("core.action.abort"),
          action: () => true,
          displayAsLink: true,
        },
      ],
    });
  }

  async columnOverlay() {
    await this.overlayService.show<DataTableColumnOverlayComponent>({
      type: DataTableColumnOverlayComponent,
      size: OverlaySize.medium,
      displayAsSidebar: true,
      header: this.i18n.instant("core.dataTable.configureColumns"),
      init: (instance) => {
        instance.config = this.config;
      },
      actions: [
        {
          label: this.i18n.instant("core.action.save"),
          action: async (instance) => {
            const newColumns: ColumnDefinition<any>[] =
              instance.getNewColumns();
            if (this.persistence) {
              this.persistence.config.columns = newColumns
                .filter(
                  (v: ColumnDefinition<any>) =>
                    isPropertyColumn(v) && !v.hidden,
                )
                .map((v: PropertyColumn<any>) => v.property.toString());
              await this.config.persistence.persistenceService.updateDataTablePersistence(
                this.persistence,
              );
            }
            this.config.columns = newColumns;
            this.setup();
            return true;
          },
        },
        {
          label: this.i18n.instant("core.action.abort"),
          action: () => {
            return true;
          },
          displayAsLink: true,
        },
      ],
    });
  }

  async setFilterValues(event: any[]) {
    this.filterStates = [];
    for (const e of event) {
      if (e.filters[0].property !== undefined) {
        this.filterStates.push(e);
      }
    }
    this.table.first = 0;
    this.load$.next(undefined);
  }

  private async handlePersistence() {
    if (this.config?.persistence?.persistenceId) {
      this.persistence =
        await this.config.persistence.persistenceService.getDataTablePersistence(
          this.config.persistence.persistenceId,
        );
      if (!this.persistence.config) {
        this.persistence.dataTableId = this.config.persistence.persistenceId;
        this.persistence.config = {};
      }
      if (!this.persistence.config.exportColumns) {
        this.persistence.config.exportColumns = [];
      }
      if (!this.persistence.config.columns) {
        this.persistence.config.columns = [];
      }
      if (!this.persistence.config.filterProfiles) {
        this.persistence.config.filterProfiles = [];
      }
      if (this.persistence.config.columns.length > 0) {
        this.persistColumns();
      }
    }
  }

  private persistColumns() {
    const persistColumns = [];
    for (const columnProperty of this.persistence.config.columns) {
      const found = this.config.columns.find((v) => {
        if (isPropertyColumn(v)) {
          if (columnProperty === v.property.toString()) {
            v.hidden = false;
            return v;
          }
        }
      });
      if (found) {
        persistColumns.push(found);
      }
    }
    this.config.columns.map((v) => {
      if (
        isPropertyColumn(v) &&
        !this.persistence.config.columns.find(
          (p) => p === v.property.toString(),
        )
      ) {
        v.hidden = true;
        persistColumns.push(v);
      } else if (!isPropertyColumn(v)) {
        persistColumns.push(v);
      }
    });
    this.config.columns = persistColumns;
  }

  getFirstOfTable() {
    return this.table.totalRecords > 0 ? this.table.first + 1 : 0;
  }

  getLastOfTable() {
    return Math.min(
      this.table.first + this.table.rows,
      this.table.totalRecords,
    );
  }
}
