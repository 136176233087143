<div>
  <div class="grid">
    <form-field class="col-6" label="Name">
      <input type="text" value="{{accountSystem.name}}" disabled>
    </form-field>
    <form-field class="col-6" label="Kürzel">
      <input type="text" value="{{accountSystem.short}}" disabled>
    </form-field>
    <form-field class="col-4" label="System">
      <input type="text" value="{{accountSystem.discr}}" disabled>
    </form-field>
    <form-field class="col-4" label="Type">
      <input type="text" value="{{accountSystem.systemType}}" disabled>
    </form-field>
    <form-field class="col-4" label="Testsystem">
      <input type="text" value="{{accountSystem.isDev}}" disabled>
    </form-field>
    <form-field class="col-6" label="Portal Name">
      <input type="text" value="{{accountSystem.portalName}}" disabled>
    </form-field>
    <form-field class="col-6" label="Cluster">
      <input type="text" value="{{accountSystem.cluster}}" disabled>
    </form-field>
    <form-field class="col-6" label="DB Name">
      <input type="text" value="{{accountSystem.dbName}}" disabled>
    </form-field>
    <form-field class="col-6" label="DB Host">
      <input type="text" value="{{accountSystem.dbServer}}" disabled>
    </form-field>
    <form-field class="col-6" label="SSL">
      <input type="text" value="{{accountSystem.useSsl}}" disabled>
    </form-field>
    <form-field class="col-6" label="Kunde">
      <input type="text" value="{{accountSystem.name}}" disabled>
    </form-field>
    <form-field class="col-6" label="Land">
      <input type="text" value="{{account.country.name}}" disabled>
    </form-field>
    <form-field class="col-6" label="Sprache">
      <input type="text" value="{{account.language.name}}" disabled>
    </form-field>
    <form-field class="col-6" label="PLZ">
      <input type="text" value="{{account.zipCode}}" disabled>
    </form-field>
    <form-field class="col-6" label="Währung">
      <input type="text" value="{{account.currency.name}}" disabled>
    </form-field>

    <form-field class="col-12" label="SubDomains">
      <div class="separator-bottom" *ngFor="let domain of accountSystem.accountSystemSubDomains">
        <input value="{{domain.subdomainLinkToSystem}}" type="text" disabled>
      </div>
    </form-field>

    <form-field class="col-12" label="E-Mails">
      <div class="separator-bottom" *ngFor="let email of account.emails">
        <input value="{{email.email}}" type="text" disabled>
      </div>
    </form-field>
  </div>
</div>
