import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ButtonModule } from "primeng/button";
import { IconButtonComponent } from "./icon-button.component";
import { TooltipModule } from "primeng/tooltip";

@NgModule({
  declarations: [IconButtonComponent],
  imports: [CommonModule, ButtonModule, TooltipModule],
  exports: [IconButtonComponent],
})
export class IconButtonModule {}
