import { Component, OnInit } from "@angular/core";
import {
  ConfirmService,
  LoadingService,
  OverlayService,
  OverlaySize,
  Status,
} from "@incert/incert-core";
import { AuthManager } from "../../../core/auth";
import {
  ArrayDataSource,
  createComponentConfiguration,
  createFilterDefinition,
  DataTableConfig,
  DataTableIconButtonsComponent,
  DateFilterComponent,
  DateFormatPipe,
  StatusComponent,
  TextFilterComponent,
} from "@incert/incert-gui";
import { Marketplace, MarketplaceType } from "./marketplace.model";
import { MarketplaceService } from "./marketplace.service";
import { MarketplaceUpsertComponent } from "./marketplace-upsert/marketplace-upsert.component";
import { BooleanFilterComponent } from "@incert/incert-gui";
import { SelectItem } from "primeng/api";
import { AccessProfile } from "../access-profile/access-profile.model";
import { AccessProfileManager } from "../access-profile/access-profile.manager.service";

@Component({
  selector: "incert-marketplace",
  templateUrl: "./marketplace.component.html",
  styleUrls: ["./marketplace.component.css"],
})
export class MarketplaceComponent implements OnInit {
  dtConfig: DataTableConfig<Marketplace>;
  marketplaces: Marketplace[] = [];
  marketplaceTypeSI: SelectItem<MarketplaceType>[];
  resourceIdSI: SelectItem[] = [
    { label: "Logo", value: "logo" },
    { label: "Website Link", value: "website_link" },
    { label: "Zendesk Referenz", value: "zendesk_reference" },
    { label: "Interner Link", value: "internal_link" },
    { label: "Interne Datei", value: "internal_file" },
  ];
  accessProfileSI: SelectItem<AccessProfile>[];

  constructor(
    private overlayService: OverlayService,
    private loadingService: LoadingService,
    private confirmService: ConfirmService,
    private authManager: AuthManager,
    private marketplaceService: MarketplaceService,
    private accessProfileManager: AccessProfileManager,
    private dateFormatPipe: DateFormatPipe,
  ) {}

  async ngOnInit() {
    await this.loadingService.load(async () => {
      await this.init();
    });
    this.marketplaceTypeSI =
      await this.marketplaceService.getMarketplaceTypesSI();
    this.accessProfileSI =
      await this.accessProfileManager.getAccessProfilesSI();
  }

  private async init() {
    this.marketplaces = await this.marketplaceService.getMarketplaces();
    await this.createDtConfig();
  }

  private async createDtConfig() {
    const arrayDataSource = new ArrayDataSource(this.marketplaces);
    this.dtConfig = {
      mode: "pagination",
      rows: 15,
      data: arrayDataSource,
      filterArea: {
        filters: [
          {
            header: "Bezeichnung",
            property: (v) => v.get("descriptions", 0, "name"),
            filter: createFilterDefinition(TextFilterComponent, {}),
          },
          {
            header: "Veröffentlicht",
            property: (v) => v.get("published"),
            filter: createFilterDefinition(BooleanFilterComponent, {}),
          },
          {
            header: "Datum",
            property: (v) => v.get("created"),
            filter: createFilterDefinition(DateFilterComponent, {
              type: "between",
            }),
          },
        ],
      },
      columns: [
        {
          header: "Bezeichnung",
          property: (v) => v.get("descriptions", 0, "name"),
          sort: true,
        },
        {
          header: "Veröffentlicht",
          property: (v) => v.get("published"),
          component: {
            component: StatusComponent,
            init: (instance: StatusComponent, row: Marketplace) => {
              instance.status = row.published ? Status.success : Status.alert;
            },
          },
          class: () => "inc-dt-center",
          width: "60px",
          sort: true,
        },
        {
          header: "Datum",
          property: (v) => v.get("created"),
          transform: (v) => this.dateFormatPipe.transform(v),
          sort: true,
        },
        {
          header: "Angefragt",
          property: (v) => v.get("statistic", "totalRequested"),
          width: "60px",
          sort: true,
        },
        {
          header: "Installiert",
          property: (v) => v.get("statistic", "totalInstalled"),
          width: "60px",
          sort: true,
        },
        {
          header: "Aktionen",
          hidden: !this.readMarketplace,
          component: createComponentConfiguration(
            DataTableIconButtonsComponent,
            {
              iconConfig: [
                {
                  hidden: !this.writeMarketplace,
                  icon: "edit",
                  onClick: (row: Marketplace) => this.overlayUpsert(row),
                  tooltip: "Bearbeiten",
                },
                {
                  hidden: !this.executeMarketplace,
                  icon: "delete",
                  onClick: (row: Marketplace) => this.delete(row),
                  tooltip: "Löschen",
                },
              ],
            },
          ),
        },
      ],
      additionalHeaderComponents: [
        createComponentConfiguration(DataTableIconButtonsComponent, {
          iconConfig: [
            {
              hidden: !this.writeMarketplace,
              label: "Marketplace",
              icon: "plus",
              onClick: () => this.overlayUpsert(),
            },
          ],
        }),
      ],
    };
  }

  public async overlayUpsert(marketplace: Marketplace = null) {
    await this.overlayService.show<MarketplaceUpsertComponent>({
      type: MarketplaceUpsertComponent,
      header: marketplace ? "Marketplace bearbeiten" : "Marketplace hinzufügen",
      size: OverlaySize.large,
      displayAsSidebar: true,
      init: async (component) => {
        if (marketplace) {
          component.marketplace = marketplace;
        }
        component.marketplaceTypeSI = this.marketplaceTypeSI;
        component.resourceIdSI = this.resourceIdSI;
        component.accessProfileSI = this.accessProfileSI;
      },
      actions: [
        {
          buttonType: "primary",
          label: "Speichern",
          action: async (component) => {
            if (await component.upsertMarketplace()) {
              await this.init();
            }
            return true;
          },
        },
        {
          label: "Abbrechen",
          action: () => true,
          displayAsLink: true,
        },
      ],
    });
  }

  public async delete(marketplace: Marketplace) {
    const response = await this.confirmService.confirmError(
      "",
      "Marketplace löschen",
    );
    if (response) {
      await this.marketplaceService.deleteMarketplace(marketplace.id);
      await this.ngOnInit();
    }
  }

  public get readMarketplace(): boolean {
    return this.authManager.hasPermission("readMarketplace");
  }

  public get writeMarketplace(): boolean {
    return this.authManager.hasPermission("writeMarketplace");
  }

  public get executeMarketplace(): boolean {
    return this.authManager.hasPermission("executeMarketplace");
  }
}
