export const incertGUII18n = {
  locale: "en",
  currency: "EUR",
  currencyByCode: {
    EUR: {
      symbolLeft: "EUR",
      symbolRight: "",
      decimalPoint: ",",
      thousandsPoint: ".",
      decimalPlaces: 2,
    },
  },
  ok: "Ok",
  abort: "Abort",

  dateFormat: "MM-DD-YYYY",
  timeFormat: "hh:mm",
  confirmDefault: "Are your sure?",

  // languages
  languages: [
    {
      id: 8,
      code: "en",
    },
  ],
  currentLanguage: {
    id: 8,
    code: "en",
  },

  // severities
  error: "Error",
  success: "Success",
  info: "Info",
  warning: "Warning",

  // DateTime
  clear: "Leeren",
  today: "Today",
  month: "Month",
  week: "Week",
  day: "Day",
  list: "List",
  allDay: "All sourceId",
  noEvents: "No Events",
  more: "more",
  dayNames: {
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
  },
  dayNamesShort: {
    monday: "Mon.",
    tuesday: "Tues.",
    wednesday: "Wed.",
    thursday: "Thurs.",
    friday: "Fri.",
    saturday: "Sat.",
    sunday: "Sun.",
  },
  dayNamesMin: {
    monday: "M",
    tuesday: "T",
    wednesday: "W",
    thursday: "Th",
    friday: "F",
    saturday: "Sa",
    sunday: "Su",
  },
  monthNames: {
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",
  },
  monthNamesShort: {
    january: "Jan",
    february: "Feb",
    march: "Mar",
    april: "Apr",
    may: "May",
    june: "Jun",
    july: "Jul",
    august: "Aug",
    september: "Sep",
    october: "Oct",
    november: "Nov",
    december: "Dec",
  },
  multiSelect: {
    selectedItemsLabel: "{0} items selected",
    defaultLabel: "Choose columns",
  },
  barcodeScanner: {
    header: "Scan Codes",
    error:
      "Camera cannot be accessed. Please allow the usage or close other apllications that use the camera.",
  },
};
