import { Component, OnInit } from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import {
  Notification,
  NotificationPermission,
  NotificationTranslation,
} from "../notification.interface";
import { NotificationManagerService } from "../notification-manager.service";
import { LoadingService, NotificationService } from "@incert/incert-core";
import { ActionInterface } from "../../../account-system/account-system-action/account-system-action.interface";
import { AccountSystem } from "../../../../core/models/account-system.interface";
import { SelectItem } from "primeng/api";
import { markAsDirty } from "@incert/incert-gui";

@Component({
  selector: "incert-internal-tools-add-new-notification",
  templateUrl: "./add-notification.component.html",
  styleUrls: ["./add-notification.component.scss"],
})
export class AddNotificationComponent implements OnInit, ActionInterface {
  public notificationCategoriesSI: Array<SelectItem>;
  public notificationPermissions: Array<NotificationPermission>;
  public notificationLanguages: Array<SelectItem>;
  public notificationBehaviors: Array<SelectItem>;
  public accountSystems: Array<AccountSystem>;
  public editNotification: Notification;
  public formGroup: UntypedFormGroup;

  constructor(
    private notificationManager: NotificationManagerService,
    private notificationService: NotificationService,
    private loadingService: LoadingService,
  ) {
    this.createFormGroup();
  }

  async ngOnInit() {
    await this.initialize();
  }

  private async initialize() {
    this.accountSystems.map((v) => {
      return (v.displayName = v.name + " | " + v.short);
    });

    if (this.editNotification) {
      this.pushExistingNotification(this.editNotification);
    } else {
      this.pushEmptyTranslationGroup();
    }
  }

  private pushExistingNotification(notification: Notification) {
    this.formGroup.patchValue({
      id: notification.id,
      permissions: notification.permissions,
      accountSystems: this.accountSystems.filter(
        (a) =>
          notification.accountSystems.map((na) => na.id).indexOf(a.id) !== -1,
      ),
      category: notification.category.severity,
      behavior: notification.behavior,
      uri: notification.uri,
      startDate: notification.startDate
        ? new Date(notification.startDate)
        : null,
      endDate: notification.endDate ? new Date(notification.endDate) : null,
    });

    this.addTranslations(notification.translations);
  }

  public async onDeleteTranslation(index: number) {
    const formArray: UntypedFormArray = this.formGroup.get(
      "translations",
    ) as UntypedFormArray;
    if (formArray.length === 1) {
      this.notificationService.notifyInfo(
        "Mindestens eine Übersetzung muss angegeben werden",
        "Löschen nicht möglich",
      );
    } else {
      try {
        formArray.removeAt(index);
      } catch (e) {
        formArray.removeAt(index);
      }
    }
  }

  private createFormGroup() {
    this.formGroup = new UntypedFormGroup({
      id: new UntypedFormControl(""),
      category: new UntypedFormControl("", Validators.required),
      behavior: new UntypedFormControl("", Validators.required),
      accountSystems: new UntypedFormControl("", Validators.required),
      translations: new UntypedFormArray([], Validators.required),
      endDate: new UntypedFormControl("", Validators.required),
      uri: new UntypedFormControl(""),
      startDate: new UntypedFormControl(""),
      permissions: new UntypedFormControl(""),
    });
  }

  public pushEmptyTranslationGroup() {
    const formArray: UntypedFormArray = this.formGroup.get(
      "translations",
    ) as UntypedFormArray;

    formArray.push(
      new UntypedFormGroup({
        language: new UntypedFormControl("de", Validators.required),
        title: new UntypedFormControl("", Validators.required),
        shortDescription: new UntypedFormControl(""),
        text: new UntypedFormControl(""),
      }),
    );
  }

  private addTranslations(
    notificationTranslation: Array<NotificationTranslation>,
  ) {
    const formArray: UntypedFormArray = this.formGroup.get(
      "translations",
    ) as UntypedFormArray;

    notificationTranslation.forEach((translation) => {
      formArray.push(
        new UntypedFormGroup({
          id: new UntypedFormControl(translation.id),
          language: new UntypedFormControl({
            value: translation.language,
            disabled: true,
          }),
          title: new UntypedFormControl(translation.title, Validators.required),
          shortDescription: new UntypedFormControl(
            translation.shortDescription,
          ),
          text: new UntypedFormControl(translation.text),
        }),
      );
    });
  }

  async onAction(data: Array<AccountSystem>, actionData: Array<AccountSystem>) {
    this.accountSystems = data;
    this.notificationPermissions =
      await this.notificationManager.getNotificationPermissions();
    this.notificationBehaviors =
      await this.notificationManager.getNotificationBehaviors();
    const preSelected = this.accountSystems.filter(
      (a) => actionData.map((d) => d.id).indexOf(a.id) !== -1,
    );

    this.formGroup.get("accountSystems").patchValue(preSelected);
  }

  async upsertNotification() {
    markAsDirty(this.formGroup);
    if (this.formGroup.valid) {
      await this.loadingService.load(async () => {
        try {
          await this.notificationManager.upsertNotification(
            this.formGroup.getRawValue(),
          );
          this.notificationService.notifySuccess(
            "Benachrichtigung wurde erfolgreich aktualisiert!",
            "Benachrichtigung aktualisiert!",
          );
        } catch (e) {
          this.notificationService.notifyError(
            "Fehler bei der Aktualisierung!",
          );
        }
      });
      return true;
    }
    return false;
  }
}
