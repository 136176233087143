import { Injectable } from "@angular/core";
import {
  AccountSystem,
  ImportConfigResponse,
} from "../../core/models/account-system.interface";
import { Subject } from "rxjs";
import { APIService } from "../../core/api.service";
import { HttpService } from "../../core";
import { RKSV } from "./account-system-rksv/account-system-rksv.interface";
import { AccountSystemGroup } from "../account-system-group/account-system-group";

@Injectable({ providedIn: "root" })
export class AccountSystemManager {
  public dataHasChanged: Subject<boolean> = new Subject<boolean>();

  constructor(
    private httpService: HttpService,
    private settingsService: APIService,
  ) {}

  public getAccountSystemsByUser(
    userId: number,
    discr = "",
  ): Promise<Array<AccountSystem>> {
    discr = discr ? "/" + discr : "";
    return this.httpService.get<Array<AccountSystem>>(
      this.settingsService.baseUrl + "accountsystem/all/" + userId + discr,
    );
  }

  public getStandaloneAccountSystems(): Promise<Array<AccountSystem>> {
    return this.httpService.get<Array<AccountSystem>>(
      this.settingsService.baseUrl + "accountsystem/internal",
    );
  }

  public addAccountSystemToUser(
    userId: number,
    accounts: Array<AccountSystem>,
  ): Promise<any> {
    return this.httpService.post(
      this.settingsService.baseUrl + "user/" + userId + "/accountsystem",
      accounts,
    );
  }

  public deleteAccountSystemFromUser(
    userId: number,
    accountSystemId: number,
  ): Promise<any> {
    return this.httpService.delete(
      this.settingsService.baseUrl +
        "user/" +
        userId +
        "/systems/" +
        accountSystemId,
    );
  }

  public async upsertAccountSystem(
    accountSystem: AccountSystem,
  ): Promise<string> {
    const errors = await this.httpService.post<string[]>(
      this.settingsService.baseUrl + "accountsystem",
      accountSystem,
    );
    if (errors.length > 0) {
      return errors[0]["name"];
    }
    return "";
  }

  public async startImport(accountSystems?: {
    accountSystems: Array<string>;
  }): Promise<ImportConfigResponse> {
    return this.httpService.post(
      this.settingsService.baseUrl + "accountsystem/set-config/",
      accountSystems ?? {},
    );
  }

  public postRKSVData(payload: RKSV): Promise<RKSV> {
    return this.httpService.post<RKSV>(
      this.settingsService.baseUrl + "rksv",
      payload,
    );
  }

  public putRKSVData(payload: RKSV): Promise<RKSV> {
    return this.httpService.put<RKSV>(
      this.settingsService.baseUrl + "rksv",
      payload,
    );
  }

  public getRKSVDataForACS(accountSystemId: number): Promise<RKSV> {
    return this.httpService.get<RKSV>(
      this.settingsService.baseUrl + "rksv/" + accountSystemId,
    );
  }

  public markForChange() {
    this.dataHasChanged.next(true);
  }

  public insertAccountSystemFilter(accountSystemFilter: any) {
    return this.httpService.post<any>(
      this.settingsService.baseUrl + "account-system-filter",
      accountSystemFilter,
    );
  }

  public async getAccountSystemServers(accountSystem: AccountSystem) {
    return await this.httpService.get<any>(
      this.settingsService.baseUrl + "server/" + accountSystem.id,
    );
  }

  public async getAccountSystemSI() {
    return await this.httpService.get<any>(
      this.settingsService.baseUrl + "accountsystem/selectitem",
    );
  }

  async getAccountSystemsByGroup(systemGroup: AccountSystemGroup) {
    return await this.httpService.get<any>(
      this.settingsService.baseUrl + "accountsystem/group/" + systemGroup.id,
    );
  }

  async getAccountSystemsByFilterSI(filterId: number) {
    const accountSystems = await this.httpService.get<AccountSystem[]>(
      this.settingsService.baseUrl +
        "accountsystem/filter" +
        (filterId ? "/" + filterId : ""),
    );
    return accountSystems.map((v) => {
      return {
        label: v.name + " | " + v.short,
        value: v.id,
      };
    });
  }

  async getAccountSystemsExcludedInGroupSI(groupId: number) {
    const accountSystems = await this.httpService.get<AccountSystem[]>(
      this.settingsService.baseUrl + "accountsystem/group/excluded/" + groupId,
    );
    return accountSystems.map((v) => {
      return {
        label: v.name + " | " + v.short,
        value: v.id,
      };
    });
  }
}
