import { RQLProperty } from "./rql-property.type";

export type LogicalConnectableOperator<T> =
  | LogicalOperator<T>
  | ScalarOperator<T>
  | ArrayOperator<T>;

export interface TypeOperator {
  type: any;
}

export interface FieldOperator<T> {
  field: RQLProperty<T>;
}

export interface LogicalOperator<T> extends TypeOperator {
  type: "and" | "or";
  operators: LogicalConnectableOperator<T>[];
}

export interface ScalarOperator<T> extends FieldOperator<T>, TypeOperator {
  type:
    | "eq"
    | "ne"
    | "lt"
    | "gt"
    | "le"
    | "ge"
    | "like"
    | "isnull"
    | "isnotnull";
  value: any;
}

export interface ArrayOperator<T> extends FieldOperator<T>, TypeOperator {
  type: "in" | "out";
  value: any[];
}

export interface SelectOperator<T> extends FieldOperator<T> {
  alias?: string;
}

export interface AggregateOperator<T> extends SelectOperator<T>, TypeOperator {
  type: "mean" | "sum" | "min" | "max" | "count" | "groupBy";
}

export interface SortOperator<T> extends FieldOperator<T> {
  order: "asc" | "desc";
}

export interface LimitOperator {
  count: number;
  start?: number;
}

export function isScalarOperator(
  operator: LogicalConnectableOperator<any>,
): operator is ScalarOperator<any> {
  return (
    (operator as ScalarOperator<any>).value !== undefined &&
    !Array.isArray((operator as ScalarOperator<any>).value)
  );
}

export function isArrayOperator(
  operator: LogicalConnectableOperator<any>,
): operator is ArrayOperator<any> {
  return (
    (operator as ArrayOperator<any>).value !== undefined &&
    Array.isArray((operator as ArrayOperator<any>).value)
  );
}

export function isLogicalOperator(
  operator: LogicalConnectableOperator<any>,
): operator is LogicalOperator<any> {
  return (operator as LogicalOperator<any>).operators !== undefined;
}
