import {
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { ColumnHostInjector } from "./column-host.injector";
import { DataTableRefresh } from "../data-table-refresh.token";
import {
  isComponentConfiguration,
  ProcessedRow,
} from "../shared/interfaces/column-definition.interface";

@Component({
  selector: "column-host",
  templateUrl: "./column-host.component.html",
  styleUrls: ["./column-host.component.css"],
})
export class ColumnHostComponent implements OnInit {
  @Input()
  public data?: ProcessedRow;

  @Input()
  public component: any;

  @Input()
  public expandedRows: {};

  @Output()
  refresh = new EventEmitter<void>();

  @ViewChild("host", { read: ViewContainerRef, static: true })
  private hostViewContainerRef: ViewContainerRef;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
  ) {}

  ngOnInit() {
    let component = this.component;
    if (isComponentConfiguration(this.component)) {
      component = this.component.component;
    }
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(component);

    this.hostViewContainerRef.clear();

    const refreshFn: DataTableRefresh = () => {
      this.refresh.emit();
    };

    const componentRef = this.hostViewContainerRef.createComponent(
      componentFactory,
      this.hostViewContainerRef.length,
      new ColumnHostInjector(this.injector, this.data?.row, refreshFn),
    );

    if (isComponentConfiguration(this.component)) {
      if (this.component.properties) {
        for (const key of Object.keys(this.component.properties)) {
          componentRef.instance[key] = this.component.properties[key];
        }
      }
      if (this.component.init) {
        this.component.init(componentRef.instance, this.data?.row, (value) => {
          if (this.data && this.expandedRows) {
            if (value) {
              this.expandedRows[this.data.key] = true;
            } else {
              delete this.expandedRows[this.data.key];
            }
          }
        });
      }
    }
  }
}
