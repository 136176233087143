<div>
  <div *ngIf="config.arrayData?.length">
    <div class="grid col-12 p-ai-center" *ngIf="config.arrayData.length > config.maxItems" [pTooltip]="hoverData">

      <div *ngFor="let arr of config.arrayData.slice(0, config.maxItems); let i = index">
        <span class="p-p-0 nowrap">{{getArrayData(arr, config.displayProperty)}}</span>
        <span class="p-mr-1" *ngIf="i !== config.arrayData.length - 1">, </span>
      </div>

      <span *ngIf="!config.customOverflowText"
            class="p-text-bold p-pl-1">(+{{config.arrayData.length - config.maxItems}})</span>
      <span *ngIf="config.customOverflowText" [ngClass]="config?.overflowClass"> {{config.customOverflowText}} </span>
    </div>

    <div *ngIf="config.arrayData.length <= config.maxItems">
      <div class="p-d-inline-flex" *ngFor="let arr of config.arrayData; let i = index">
        <span class="nowrap"> {{getArrayData(arr, config.displayProperty)}}</span>
        <span class="p-mr-1" *ngIf="i !== config.arrayData.length - 1">, </span>
      </div>
    </div>
  </div>
  <div *ngIf="!config.arrayData?.length && config.fallbackString">
    <div class="p-text-center p-text-italic"> {{config.fallbackString}}</div>
  </div>
</div>
