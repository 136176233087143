import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "./pages/login";
import { AuthGuard } from "./core/auth";
import { ToolsOverviewComponent } from "./pages/tools/tools-overview.component";
import { HistoryProtocolComponent } from "./pages/protocol/history-protocol/history-protocol.component";
import { UserDetailComponent, UserOverviewComponent } from "./pages/user";
import { AccountOverviewComponent } from "./pages/account/account-overview/account-overview.component";
import { NotificationComponent } from "./pages/tools/notification/notification.component";
import { RoleOverviewComponent } from "./pages/role/role-overview/role-overview.component";
import { ServiceProtocol } from "./pages/protocol/service-protocol/service-protocol.component";
import { AccessProfileComponent } from "./pages/tools/access-profile/access-profile.component";
import { ContactGroupComponent } from "./pages/tools/contact-group/contact-group.component";
import { ContactEmailComponent } from "./pages/tools/contact-email/contact-email.component";
import { InfosComponent } from "./pages/infos/infos.component";
import { AccountSystemComponent } from "./pages/account-system/account-system.component";
import { AccountSystemGroupComponent } from "./pages/account-system-group/account-system-group.component";
import { InstallationHistoryComponent } from "./pages/protocol/installation-history/installation-history.component";
import { I18nGuard, I18nRouteData } from "@incert/i18n";
import { MarketplaceComponent } from "./pages/tools/marketplace/marketplace.component";
import { SystemValueComponent } from "./pages/tools/system-value/system-value.component";

export enum RoutesDef {
  //account
  accountOverview = "account-overview",
  //system
  accountSystemOverview = "account-system-overview",
  accountSystemGroup = "account-system-group",
  //tools
  tools = "tools",
  memo = "memo",
  notification = "notification",
  systemValue = "system-value",
  contact = "contact",
  contactGroup = "contact-group",
  accessProfile = "access-profile",
  marketplace = "marketplace",
  //library
  info = "info",
  historyLoginProtocol = "history-protocol/login",
  historyChangeProtocol = "history-protocol/change",
  historyAccessProtocol = "history-protocol/access",
  protocolInstallationHistory = "protocol/installation-history",
  historyServiceProtocol = "history-protocol/service-protocol",
  //admin
  userOverview = "user-overview",
  userDetail = "user-detail",
  role = "role",
}

const routes: Routes = [
  {
    path: "",
    component: LoginComponent,
  },
  {
    path: RoutesDef.tools,
    canActivate: [AuthGuard],
    component: ToolsOverviewComponent,
  },
  {
    path: RoutesDef.memo,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./pages/tools/memo/memo.module").then((m) => m.MemoModule),
  },
  {
    path: RoutesDef.accountSystemOverview,
    component: AccountSystemComponent,
    canActivate: [AuthGuard, I18nGuard],
    data: <I18nRouteData>{
      i18nPaths: ["core"],
    },
  },
  {
    path: RoutesDef.notification,
    component: NotificationComponent,
    canActivate: [AuthGuard, I18nGuard],
    data: <I18nRouteData>{
      i18nPaths: ["core"],
    },
  },
  {
    path: RoutesDef.systemValue,
    component: SystemValueComponent,
    canActivate: [AuthGuard, I18nGuard],
    data: <I18nRouteData>{
      i18nPaths: ["core"],
    },
  },
  {
    path: RoutesDef.userOverview,
    component: UserOverviewComponent,
    canActivate: [AuthGuard, I18nGuard],
    data: <I18nRouteData>{
      i18nPaths: ["core"],
    },
  },
  {
    path: RoutesDef.userDetail,
    component: UserDetailComponent,
    canActivate: [AuthGuard, I18nGuard],
    data: <I18nRouteData>{
      i18nPaths: ["core"],
    },
  },
  {
    path: RoutesDef.accountSystemGroup,
    component: AccountSystemGroupComponent,
    canActivate: [AuthGuard, I18nGuard],
    data: <I18nRouteData>{
      i18nPaths: ["core"],
    },
  },
  {
    path: RoutesDef.role,
    component: RoleOverviewComponent,
    canActivate: [AuthGuard, I18nGuard],
    data: <I18nRouteData>{
      i18nPaths: ["core"],
    },
  },
  {
    path: RoutesDef.historyLoginProtocol,
    component: HistoryProtocolComponent,
    canActivate: [AuthGuard, I18nGuard],
    data: <I18nRouteData>{
      i18nPaths: ["core"],
    },
  },
  {
    path: RoutesDef.historyChangeProtocol,
    component: HistoryProtocolComponent,
    canActivate: [AuthGuard, I18nGuard],
    data: <I18nRouteData>{
      i18nPaths: ["core"],
    },
  },
  {
    path: RoutesDef.historyAccessProtocol,
    component: HistoryProtocolComponent,
    canActivate: [AuthGuard, I18nGuard],
    data: <I18nRouteData>{
      i18nPaths: ["core"],
    },
  },
  {
    path: RoutesDef.historyServiceProtocol,
    component: ServiceProtocol,
    canActivate: [AuthGuard, I18nGuard],
    data: <I18nRouteData>{
      i18nPaths: ["core"],
    },
  },
  {
    path: RoutesDef.protocolInstallationHistory,
    component: InstallationHistoryComponent,
    canActivate: [AuthGuard, I18nGuard],
    data: <I18nRouteData>{
      i18nPaths: ["core"],
    },
  },
  {
    path: RoutesDef.accountOverview,
    component: AccountOverviewComponent,
    canActivate: [AuthGuard, I18nGuard],
    data: <I18nRouteData>{
      i18nPaths: ["core"],
    },
  },
  {
    path: RoutesDef.contact,
    component: ContactEmailComponent,
    canActivate: [AuthGuard, I18nGuard],
    data: <I18nRouteData>{
      i18nPaths: ["core"],
    },
  },
  {
    path: RoutesDef.contactGroup,
    component: ContactGroupComponent,
    canActivate: [AuthGuard, I18nGuard],
    data: <I18nRouteData>{
      i18nPaths: ["core"],
    },
  },
  {
    path: RoutesDef.accessProfile,
    component: AccessProfileComponent,
    canActivate: [AuthGuard, I18nGuard],
    data: <I18nRouteData>{
      i18nPaths: ["core"],
    },
  },
  {
    path: RoutesDef.info,
    component: InfosComponent,
    canActivate: [AuthGuard, I18nGuard],
    data: <I18nRouteData>{
      i18nPaths: ["core"],
    },
  },
  {
    path: RoutesDef.marketplace,
    canActivate: [AuthGuard, I18nGuard],
    component: MarketplaceComponent,
    data: <I18nRouteData>{
      i18nPaths: ["core"],
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      onSameUrlNavigation: "reload",
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
