import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  HostBinding,
  Input,
  OnDestroy,
  ViewContainerRef,
} from "@angular/core";
import {
  AbstractControl,
  FormControlDirective,
  FormControlName,
  NgControl,
  ValidatorFn,
} from "@angular/forms";
import { ValidationMessageService } from "../validators/validation-message-service";
import { debounceTime } from "rxjs/operators";
import { Subscription } from "rxjs";

@Component({
  selector: "form-field",
  templateUrl: "./form-field.component.html",
  styleUrls: ["./form-field.component.scss"],
})
export class FormFieldComponent implements AfterViewInit, OnDestroy {
  // count usages
  static idCount = 0;

  public id: string;

  @Input() public label: string | null = null;
  @Input() public clearSeparatorBottom;
  @Input() public displayAllErrors: boolean;
  @Input() public displayLabel = true;
  @Input() public helpText: string;
  @Input() public displayInline = false;
  @Input() public floatingLabel = false;
  @Input() public additionalInfoMessage: string; //Add additional info message on bottom of form-field (e.g. 'Add more than 3 chars')

  @HostBinding("style.display") get hostDisplay(): string {
    return this.displayInline ? "inline-block" : "block";
  }

  @ContentChild(FormControlDirective, { read: ViewContainerRef, static: true })
  private formControlViewContainerRef: ViewContainerRef;

  @ContentChild(FormControlName, { read: ViewContainerRef, static: true })
  private formControlNameViewContainerRef: ViewContainerRef;

  public aria = false;

  public formControl: AbstractControl;

  public required = false;
  public messages = null;
  private valueChangesSubscription: Subscription;
  private statusChangesSubscription: Subscription;
  private validator: ValidatorFn;

  public constructor(
    private vcRef: ViewContainerRef,
    private cdr: ChangeDetectorRef,
    private validationMessageService: ValidationMessageService,
  ) {
    this.id = "form-element-" + FormFieldComponent.idCount;
    FormFieldComponent.idCount++;
  }

  ngAfterViewInit(): void {
    // is reactive form element
    if (
      this.formControlViewContainerRef ||
      this.formControlNameViewContainerRef
    ) {
      if (this.formControlViewContainerRef) {
        this.formControl =
          this.formControlViewContainerRef.injector.get(NgControl).control;
      } else {
        this.formControl =
          this.formControlNameViewContainerRef.injector.get(NgControl).control;
      }

      // formControllNameVContainer never gets called in the if statement (at least when creating a POI)
      // sometimes formControl had the value "undefined" which caused the errors
      if (this.formControl == undefined) return;

      this.setRequired();
      this.valueChangesSubscription = this.formControl.valueChanges
        .pipe(debounceTime(200))
        .subscribe((v) => {
          this.setRequired();
        });

      this.setMessage();
      this.statusChangesSubscription = this.formControl.statusChanges
        .pipe(debounceTime(200))
        .subscribe((v) => {
          this.setMessage();
        });
      this.cdr.detectChanges();
    }
  }

  private setRequired() {
    if (
      this.formControl.validator &&
      this.formControl.validator !== this.validator
    ) {
      const result = this.formControl.validator({} as AbstractControl);
      this.required = result && "required" in result;
      this.validator = this.formControl.validator;
    }
  }

  private setMessage() {
    if (this.formControl.invalid === true) {
      const keys = Object.keys(this.formControl.errors);
      this.messages = [];

      if (this.displayAllErrors) {
        for (const key of keys) {
          this.messages.push(
            this.validationMessageService.getMessage(
              key,
              this.formControl.errors[key],
            ),
          );
        }
      } else {
        // Display only one error
        const key = keys[keys.length - 1];
        this.messages.push(
          this.validationMessageService.getMessage(
            key,
            this.formControl.errors[key],
          ),
        );
      }
    }
  }

  ngOnDestroy(): void {
    if (this.statusChangesSubscription) {
      this.statusChangesSubscription.unsubscribe();
    }
    if (this.valueChangesSubscription) {
      this.valueChangesSubscription.unsubscribe();
    }
  }
}
