import { NgModule } from "@angular/core";
import { IncertTagComponent } from "./tag.component";
import { CommonModule } from "@angular/common";
import { IconModule } from "../..";
import { TooltipModule } from "primeng/tooltip";
import { TagsComponent } from "./tags/tags.component";

@NgModule({
  declarations: [IncertTagComponent, TagsComponent],
  imports: [CommonModule, IconModule, TooltipModule],
  exports: [IncertTagComponent, TagsComponent],
})
export class IncertTagModule {}
