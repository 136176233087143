import { NgModule } from "@angular/core";
import { BooleanFilterComponent } from "./boolean-filter.component";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { InputTextModule } from "primeng/inputtext";
import { DropdownModule } from "primeng/dropdown";

@NgModule({
  declarations: [BooleanFilterComponent],
  imports: [CommonModule, ReactiveFormsModule, InputTextModule, DropdownModule],
})
export class BooleanFilterModule {}
