import { NgModule } from "@angular/core";
import { AccountSystemNewComponent } from "./account-system-new/account-system-new.component";
import { AccountSystemActionComponent } from "./account-system-action/account-system-action.component";
import { ClipboardOutletModule } from "../../../../../../shared-libs/incert-gui/src/lib/outlet/clipboard-outlet/clipboard-outlet.module";
import { CustomerListDataTableModule } from "../../core/data-table-components/customer-list-data-table.module";
import { CustomerListPrimeModule } from "../../customer-list-prime.module";
import { CustomerListSharedModule } from "../../customer-list-shared.module";
import { DataTableModule, FormExtensionModule } from "@incert/incert-gui";
import { AutoCompleteModule } from "primeng/autocomplete";
import { CardModule } from "primeng/card";
import { ToggleButtonModule } from "primeng/togglebutton";
import { AccountSystemConfigFilterSaveComponent } from "./account-system-config-filter/account-system-config-filter-save/account-system-config-filter-save.component";
import { AccountSystemComponent } from "./account-system.component";
import { AccountSystemGroupComponent } from "../account-system-group/account-system-group.component";
import { AccountSystemRksvComponent } from "./account-system-rksv/account-system-rksv.component";
import { AccountSystemMemoComponent } from "./account-system-overlays/account-system-memo/account-system-memo.component";
import { AccountSystemServerComponent } from "./account-system-overlays/account-system-server/account-system-server.component";
import { AccountSystemInstallationStatusComponent } from "./account-system-overlays/account-system-installation-status/account-system-installation-status.component";
import { AccountSystemSystemValueComponent } from "./account-system-overlays/account-system-system-value/account-system-system-value.component";
import { AccountSystemShowDetailsComponent } from "./account-system-overlays/account-system-show-details/account-system-show-details.component";
import { AccountSystemGroupUpsertComponent } from "../account-system-group/account-system-group-upsert/account-system-group-upsert.component";
import { AccountSystemGroupInfoComponent } from "../account-system-group/account-system-group-info/account-system-group-info.component";
import { AccountSystemConfigFilterComponent } from "./account-system-config-filter/account-system-config-filter.component";

@NgModule({
  imports: [
    CustomerListSharedModule,
    CustomerListPrimeModule,
    CustomerListDataTableModule,
    AutoCompleteModule,
    CardModule,
    ToggleButtonModule,
    ClipboardOutletModule,
    DataTableModule,
    FormExtensionModule,
  ],
  declarations: [
    AccountSystemComponent,
    AccountSystemGroupComponent,
    AccountSystemActionComponent,
    AccountSystemNewComponent,
    AccountSystemGroupUpsertComponent,
    AccountSystemGroupInfoComponent,
    AccountSystemShowDetailsComponent,
    AccountSystemRksvComponent,
    AccountSystemConfigFilterComponent,
    AccountSystemConfigFilterSaveComponent,
    AccountSystemServerComponent,
    AccountSystemInstallationStatusComponent,
    AccountSystemSystemValueComponent,
    AccountSystemMemoComponent,
  ],
  exports: [
    AccountSystemComponent,
    AccountSystemGroupComponent,
    AccountSystemActionComponent,
    AccountSystemNewComponent,
  ],
})
export class AccountSystemModule {}
