import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  Type,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import {
  OVERLAY_SUBJECT,
  OverlayAction,
  OverlayCallback,
  OverlayParam,
  OverlayService,
  Severity,
} from "@incert/incert-core";
import { Observable, Subject, Subscription } from "rxjs";
import { ConfirmParam } from "@incert/incert-core";
import { CONFIRM_SUBJECT } from "@incert/incert-core";
import { ConfirmTemplateComponent } from "./confirm-template.component";
import { INCERT_GUI_I18N, IncertGUII18n } from "../../incert-gui-i18n.token";
import { OverlayOutletParam } from "./overlay-outlet-param.model";

@Component({
  selector: "overlay-outlet",
  templateUrl: "./overlay-outlet.component.html",
})
export class OverlayOutletComponent implements OnDestroy {
  public confirmType = "";

  public overlayParams: OverlayOutletParam[] = [];

  private confirmSubjectSubscription: Subscription = null;

  private overlaySubjectSubscription: Subscription = null;

  constructor(
    private overlayService: OverlayService,
    @Inject(OVERLAY_SUBJECT) private overlaySubject: Subject<OverlayParam>,
    @Inject(CONFIRM_SUBJECT) private confirmSubject: Subject<ConfirmParam>,
    @Inject(INCERT_GUI_I18N) private incertGUIConfig: IncertGUII18n,
    private cdr: ChangeDetectorRef,
  ) {
    // handle confirm
    this.confirmSubjectSubscription = this.confirmSubject.subscribe(
      (confirmParam) => {
        this.confirmType =
          "confirm-overlay confirm-" + Severity[confirmParam.severity];
        this.overlayService.show<ConfirmTemplateComponent>({
          header: confirmParam.header,
          type: ConfirmTemplateComponent,
          dismissableMask: false,
          closable: false,
          init: (component) => {
            component.content = confirmParam.content;
          },
          actions: [
            {
              label: incertGUIConfig.ok,
              buttonType: "primary",
              action: () => {
                confirmParam.resolve(true);
                return true;
              },
            },
            {
              label: incertGUIConfig.abort,
              displayAsLink: true,
              action: () => {
                confirmParam.resolve(false);
                return true;
              },
            },
          ],
        });
      },
    );

    this.overlaySubjectSubscription = this.overlaySubject.subscribe(
      (overlayParam: OverlayParam) => {
        const overlayOutletParam: OverlayOutletParam = {
          ...overlayParam,
          closable: true,
          visible: true,
          dismissableMask: true,
        };

        if (typeof overlayParam.overlayModel.closable !== "undefined") {
          overlayOutletParam.closable = overlayParam.overlayModel.closable;
        }
        if (typeof overlayParam.overlayModel.dismissableMask !== "undefined") {
          overlayOutletParam.dismissableMask =
            overlayParam.overlayModel.dismissableMask;
        }

        overlayOutletParam.subscription =
          overlayParam.handle.headerChange$.subscribe(() =>
            this.cdr.detectChanges(),
          );
        this.overlayParams.push(overlayOutletParam);
        overlayParam.promise.then(() => {
          this.close(overlayOutletParam);
        });
      },
    );
  }

  ngOnDestroy() {
    this.confirmSubjectSubscription.unsubscribe();
    this.overlaySubjectSubscription.unsubscribe();
  }

  public async doAction(
    callback: OverlayCallback<any>,
    overlayParam: OverlayOutletParam,
  ) {
    const result = await callback(overlayParam.componentInstance);
    if (result) {
      this.close(overlayParam);
    }
  }

  close(overlayParam: OverlayOutletParam) {
    overlayParam.visible = false;
  }

  remove(overlayParam: OverlayOutletParam) {
    overlayParam.subscription.unsubscribe();
    overlayParam.resolve();

    const index = this.overlayParams.indexOf(overlayParam);
    if (index !== -1) {
      this.overlayParams.splice(index, 1);
    }
  }
}
