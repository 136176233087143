import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IconModule } from "../../icon/icon.module";
import { BrowserModule } from "@angular/platform-browser";
import { DataTableOptionIconComponent } from "./data-table-option-icon.component";
import { TooltipModule } from "primeng/tooltip";

@NgModule({
  declarations: [DataTableOptionIconComponent],
  imports: [BrowserModule, CommonModule, IconModule, TooltipModule],
  exports: [DataTableOptionIconComponent],
})
export class DataTableOptionIconModule {}
