<form-field [label]="multiSelectLabel">
    <p-multiSelect [formControl]="selectionFormControl" [options]="_selectItems"></p-multiSelect>
</form-field>
<table class="separator-top" [formGroup]="selectItemsFormGroup">
    <thead>
        <tr>
            <th class="align-left"></th>
            <th class="align-left">{{columnHeader[0]}}</th>
            <th class="align-right">{{columnHeader[1]}}</th>
            <th class="align-right">{{columnHeader[2]}}</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let item of sortedSelectedItems; let i = index"
            pDraggable="dd"
            pDroppable="dd"
            (onDragStart)="onDragStart(item, i)"
            (onDrop)="onDropElement(i)"
        >
            <td class="align-left"><incert-icon class="icon-link" iconType="arrows"></incert-icon></td>
            <td class="align-left">{{item.label}}</td>
            <td class="align-right">
                <p-checkbox
                        [name]="item.label"
                        [formControlName]="item.value"
                        [binary]="true"
                        [pTooltip]="getToolTip(selectItemsFormGroup.get(item.value).disabled)"
                        tooltipPosition="left"
                >
                </p-checkbox>
            </td>
            <td class="align-right">
                <icon-button *ngIf="i !== 0" iconType="sortUp" (click)="sortUp(i)"></icon-button>
                <icon-button *ngIf="i !== sortedSelectedItems.length-1" iconType="sortDown" (click)="sortDown(i)"></icon-button>
            </td>
        </tr>
    </tbody>
</table>
