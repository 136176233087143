<input *ngIf="config.type === 'between'"
       class="pointer" type="text" pInputText
       [placeholder]="config?.header"
       [pTooltip]="config.tooltip"
       tooltipPosition="bottom"
       (click)='toggle($event, op)'
       readonly="readonly"
       [value]="(form.get('inputLow').value !== null && form.get('inputHigh').value !== null)
       ? (form.get('inputLow').value + ' - ' + form.get('inputHigh').value) : ''">
<input *ngIf="config.type !== 'between'"
       type="number"
       [placeholder]="config?.header"
       [pTooltip]="config.tooltip"
       tooltipPosition="bottom"
       [formControl]="form.get('inputLow')">

<p-overlayPanel #op appendTo="body">
  <form-field label="{{'core.dateTime.from' | i18n}}">
    <input
      type="number"
      [formControl]="form.get('inputLow')">
  </form-field>
  <form-field label="{{'core.dateTime.to' | i18n}}">
    <input type="number"
           [formControl]="form.get('inputHigh')">
  </form-field>
</p-overlayPanel>
