import {Component, EventEmitter, Inject} from "@angular/core";
import {
  DataTableFilterComponent,
  DataSourceFilter,
  FILTER_CONFIG,
  FilterConfig,
} from "../../data-table";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {NumberFilterExpRangeService} from "./number-filter-exp-range.service";
import {OverlayPanel} from "primeng/overlaypanel";
import {DataTableFilterService} from "../data-table-filter-service";

interface Config extends FilterConfig {
  type?: "ge" | "le" | "gt" | "lt" | "eq" | "between";
  min?: number;
  max?: number;
}

@Component({
  selector: "date-range-filter",
  templateUrl: "./number-filter.component.html",
  styleUrls: ["./number-filter.component.scss"],
  providers: [NumberFilterExpRangeService],
})
export class NumberFilterComponent implements DataTableFilterComponent {
  config: Config = {
    header: "",
    defaultValue: null,
    type: "gt",
    min: 0,
    max: 100,
  };
  change = new EventEmitter<DataSourceFilter>();
  form: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    @Inject(FILTER_CONFIG) config: Config,
    private dataTableFilterService: DataTableFilterService,
  ) {
    this.config = Object.assign(this.config, config);
    let inputLow = this.config.defaultValue ? this.config.defaultValue[0] : null;
    let inputHigh = this.config.defaultValue ? this.config.defaultValue[1] ? this.config.defaultValue[1] : inputLow : null;
    this.form = this.fb.group({
      inputLow: inputLow,
      inputHigh: inputHigh
    });
    this.form.valueChanges.subscribe((v) => {
      this.change.emit(this.buildFilter(v));
    });
  }

  //BACKENDGMS-1359 filter made simpler for now, so it actually works

  //filter updates when called; method can get called, sending value as parameter
  async getFilter() {
    if (this.config.method) {
      await this.config.method([this.form.get("inputLow").value, this.form.get("inputHigh").value]);
      return null;
    }
    return this.buildFilter([this.form.get("inputLow").value, this.form.get("inputHigh").value]);
  }

  setValue(val) {
    let inputLow = val ? val[0] : null;
    let inputHigh = val ? val[1] ? val[1] : inputLow : null;
    this.form.get("inputLow").patchValue(inputLow);
    this.form.get("inputHigh").patchValue(inputHigh);
  }

  reset(): void {
    this.form.get("inputLow").reset();
    this.form.get("inputHigh").reset();
  }

  buildFilter(v): DataSourceFilter {
    if (this.config.type === "between") {
      if (!Array.isArray(v) || v.length !== 2 || v[0] === null || v[1] === null) {
        return this.dataTableFilterService.getDataTableFilter(this.config, null);
      }
    }
    return this.dataTableFilterService.getDataTableFilter(this.config, v);
  }

  toggle(event: MouseEvent, op: OverlayPanel) {
    if (op && event) {
      op.toggle(event);
    }
  }
}
