<div class="inc-nested-list">
  <ul>
    <li *ngFor="let item of menuItems;let i = index">
      <ng-container *ngIf="!item.disabled">
        <div #node class="menu-item">
          <a (click)="selectedMenuItemIndex(i,item)"
             [ngClass]="selectedMenuItem.vi === null && selectedMenuItem.hi === 0 ?
           {'active-item-menu':selectedMenuItem.hi === i}:
           (selectedMenuItem.hi === 0 ?
            {'active-item-menu':selectedMenuItem.vi === i}:
            {'active-item-menu':selectedMenuItem.vi === i})">
            {{item.label}}
          </a>
          <p-contextMenu *ngIf="item.contextMenu" [target]="node" appendTo="body" [model]="item.contextMenu"></p-contextMenu>
        </div>
        <div *ngIf="item.expanded && selectedMenuItem.vi === i" class="inc-nested-list-sub-list">
          <incert-nested-list *ngIf="item.items" [menuItems]="item.items" [index]="index+1"></incert-nested-list>
        </div>
      </ng-container>
    </li>
  </ul>
</div>


