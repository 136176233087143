<div class="image-wrapper-error" *ngIf="hasError && defaultImage">
    <incert-icon iconType="images"></incert-icon>
</div>
<div class="image-wrapper {{roundedImg}} {{previewImg}}" *ngIf="!hasError" [pTooltip]="tooltip" [tooltipPosition]="'bottom'" (click)="openPreview()">
  <img [src]="trustedSrc"
       (error)="handleError($event)"
       (load)="load.next($event)"
       (loadeddata)="loadeddata.next($event)"
       (loadedmetadata)="loadedmetadata.next($event)"
       (loadstart)="loadstart.next($event)"
       (lostpointercapture)="lostpointercapture.next($event)"
       [ngStyle]="{ 'maxWidth': maxWidth, 'maxHeight': maxHeight }" />
</div>
