import { Component, OnInit } from "@angular/core";
import {
  Marketplace,
  MarketplaceDescription,
  MarketplaceResource,
  MarketplaceType,
} from "../marketplace.model";
import { NotificationService } from "@incert/incert-core";
import { MarketplaceService } from "../marketplace.service";
import { markAsDirty, modelToForm } from "@incert/incert-gui";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { SelectItem } from "primeng/api";
import { AccessProfile } from "../../access-profile/access-profile.model";
import { APIService } from "../../../../core/api.service";

@Component({
  selector: "incert-marketplace-upsert",
  templateUrl: "./marketplace-upsert.component.html",
  styleUrls: ["./marketplace-upsert.component.scss"],
})
export class MarketplaceUpsertComponent implements OnInit {
  marketplace: Marketplace;
  marketplaceTypeSI: SelectItem<MarketplaceType>[];
  resourceIdSI: SelectItem[];
  accessProfileSI: SelectItem<AccessProfile>[];
  formGroup: UntypedFormGroup;
  filesToDelete: any[] = [];

  constructor(
    private marketplaceService: MarketplaceService,
    private notificationService: NotificationService,
    private settingsService: APIService,
    private fb: UntypedFormBuilder,
  ) {
    this.formGroup = this.fb.group({
      published: [false, [Validators.required]],
      types: [[], [Validators.required]],
      accessProfiles: [[]],
      translations: this.fb.array([
        this.fb.group({
          description: this.fb.group(
            modelToForm<MarketplaceDescription>({
              name: [""],
            }),
          ),
          resources: this.fb.array([]),
          language: ["de"],
        }),
        this.fb.group({
          description: this.fb.group(
            modelToForm<MarketplaceDescription>({
              name: [""],
            }),
          ),
          resources: this.fb.array([]),
          language: ["en"],
        }),
      ]),
    });
  }

  async ngOnInit() {
    this.patchFormGroup(this.marketplace);
  }

  private async patchFormGroup(marketplace: Marketplace) {
    this.formGroup.patchValue(marketplace);
    if (marketplace?.types) {
      this.patchTypes(marketplace.types);
    }
    if (marketplace?.accessProfiles) {
      this.patchAccessProfiles(marketplace.accessProfiles);
    }
    marketplace?.descriptions.forEach((description: MarketplaceDescription) => {
      for (const translation of this.translations) {
        if (translation.value.language === description.language) {
          this.addDescription(translation, description);
        }
      }
    });
    if (marketplace?.resources) {
      for (const resource of marketplace.resources) {
        for (const translation of this.translations) {
          if (translation.value.language === resource.language) {
            await this.addResource(translation, resource);
          }
        }
      }
    }
  }

  patchTypes(types: MarketplaceType[]) {
    const typesV = types.map((v) => {
      return v.id;
    });
    this.formGroup.get("types").patchValue(typesV);
  }

  patchAccessProfiles(accessProfiles: AccessProfile[]) {
    const accessProfilesV = accessProfiles.map((v) => {
      return v.id;
    });
    this.formGroup.get("accessProfiles").patchValue(accessProfilesV);
  }

  addDescription(
    translation: UntypedFormGroup,
    description: MarketplaceDescription,
  ) {
    this.getDescription(translation).get("name").patchValue(description.name);
  }

  async addResource(
    translation: UntypedFormGroup,
    resource: MarketplaceResource = null,
  ) {
    const resourceFormGroup = this.fb.group(
      modelToForm<MarketplaceResource>({
        id: resource ? resource.id : null,
        resourceId: resource ? resource.resourceId : "",
        resourceType: resource ? resource.resourceType : "",
        resourceData: resource ? resource.resourceData : "",
        fileData: null,
      }),
    );
    this.getResourcesArray(translation).push(resourceFormGroup);
  }

  deleteResource(
    resource: UntypedFormGroup,
    translation: UntypedFormGroup,
    resourceId: number,
  ) {
    if (resource.value.resourceType === "file") {
      if (resource?.value?.resourceData) {
        this.filesToDelete.push(resource.value.resourceData);
      }
    }
    const resourcesArray = this.getResourcesArray(translation);
    resourcesArray.removeAt(resourceId);
  }

  public async upsertMarketplace() {
    markAsDirty(this.formGroup);
    if (this.formGroup.valid) {
      try {
        const marketplace = await this.buildMarketplace();
        await this.marketplaceService.upsertMarketplace(marketplace);
      } catch (e) {
        this.notificationService.notifyError(e, "Hinzufügen nicht möglich");
        return false;
      }
      return true;
    }
    return false;
  }

  async buildMarketplace(): Promise<Marketplace> {
    const descriptions: MarketplaceDescription[] = [];
    for (const translation of this.translations) {
      descriptions.push({
        language: translation.value.language,
        name: this.getDescription(translation).get("name").value,
      });
    }
    const types: MarketplaceType[] = [];
    for (const type of this.formGroup.get("types").value) {
      types.push({
        id: type,
      });
    }
    const accessProfiles: AccessProfile[] = [];
    for (const accessProfile of this.formGroup.get("accessProfiles").value) {
      accessProfiles.push({
        id: accessProfile,
      });
    }
    for (const fileToDelete of this.filesToDelete) {
      await this.marketplaceService.deleteFile(fileToDelete);
    }
    const resources: MarketplaceResource[] = [];
    for (const translation of this.translations) {
      for (const resource of this.getResources(translation)) {
        if (resource.value.resourceType === "file") {
          if (resource?.value?.fileData) {
            const file: File = resource.value.fileData[0];
            const uploaded = await this.marketplaceService.uploadFile(file);
            if (uploaded) {
              resource.value.resourceData = uploaded;
            }
          }
        }
        resources.push({
          id: resource.value.id,
          language: translation.value.language,
          resourceType: resource.value.resourceType,
          resourceId: resource.value.resourceId,
          resourceData: resource.value.resourceData,
        });
      }
    }
    return {
      id: this.marketplace?.id ? this.marketplace.id : null,
      published: this.formGroup.value.published,
      types: types,
      accessProfiles: accessProfiles,
      descriptions: descriptions,
      resources: resources,
    };
  }

  get translations(): any {
    return (this.formGroup.get("translations") as UntypedFormArray).controls;
  }

  getDescription(translation: UntypedFormGroup): any {
    return translation.get("description") as UntypedFormGroup;
  }

  getResources(translation: UntypedFormGroup): any {
    return (translation.get("resources") as UntypedFormArray).controls;
  }

  getResourcesArray(translation: UntypedFormGroup): any {
    return translation.get("resources") as UntypedFormArray;
  }

  getLanguageHeader(language: string) {
    return language === "de" ? "Deutsch" : "Englisch";
  }

  getResourceLabel(resource: UntypedFormGroup) {
    return this.resourceIdSI.find(
      (v) => v.value === resource?.value?.resourceId,
    )?.label;
  }

  isResourceType(resource: UntypedFormGroup, type: string) {
    return resource?.value?.resourceType === type;
  }

  setResourceType(resource: UntypedFormGroup) {
    resource.get("resourceData").patchValue("");
    switch (resource.value.resourceId) {
      case "logo":
      case "internal_file":
        resource.get("resourceType").patchValue("file");
        break;
      case "website_link":
      case "zendesk_reference":
      case "internal_link":
        resource.get("resourceType").patchValue("text");
        break;
    }
  }

  openResource(fileName: string) {
    window.open(
      this.settingsService.mediaLibraryUrl + "/customerList/" + fileName,
      "_blank",
    );
  }
}
