<form [formGroup]="form">
  <p-multiSelect
    [defaultLabel]="placeholder"
    [options]="options"
    [group]="true"
    [formControl]="fc"
    [resetFilterOnHide]="true"
    [maxSelectedLabels]="5"
    [selectedItemsLabel]="'core.multiSelect.selectedItemsLabel' | i18n"
    (onChange)="checkGroupCheckboxes()"
    (onPanelShow)="checkGroupCheckboxes()"
    (onPanelHide)="checkGroupCheckboxes()"
  >
    <ng-template let-group pTemplate="group">
      <p-checkbox
        [formControlName]="group.value"
        [label]="group.label"
        [binary]="true"
        (onChange)="this.onGroupToggle(group.items, $event.checked)"
      ></p-checkbox>
    </ng-template>
    <ng-template let-item pTemplate="item">
      {{item.label}}
    </ng-template>
  </p-multiSelect>
</form>
