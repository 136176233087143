import {
  AggregateRQLQuery,
  SelectRQLQuery,
} from "../interfaces/rql-query.interface,";
import { createRqlField } from "./create-rql-field.function";
import {
  ArrayOperator,
  isArrayOperator,
  isLogicalOperator,
  isScalarOperator,
  LogicalConnectableOperator,
  ScalarOperator,
} from "../interfaces/rql-operator.interface";
import { toRqlDate } from "../../functions";

export type RQLQuery<T> = SelectRQLQuery<T> | AggregateRQLQuery<T>;
export type CombinedRQLQuery<T> = SelectRQLQuery<T> & AggregateRQLQuery<T>;

export function mergeRqlQuery<T>(a: RQLQuery<T>, b: RQLQuery<T>): RQLQuery<T> {
  const merged: RQLQuery<T> = {
    ...a,
    ...b,
  };
  for (const key of Object.keys(merged)) {
    if (Array.isArray(merged[key])) {
      if (key in a && key in b) {
        merged[key] = a[key].concat(b[key]);
      }
    }
  }
  return merged;
}

export function createRqlQuery<T>(rqlQuery: RQLQuery<T>): CombinedRQLQuery<T> {
  const combinedRqlQuery = <CombinedRQLQuery<T>>rqlQuery;
  if (!combinedRqlQuery.limit) {
    combinedRqlQuery.limit = null;
  }
  if (!combinedRqlQuery.where) {
    combinedRqlQuery.where = [];
  }
  if (!combinedRqlQuery.sort) {
    combinedRqlQuery.sort = [];
  }
  if (!combinedRqlQuery.select) {
    combinedRqlQuery.select = [];
  }
  if (!combinedRqlQuery.aggregate) {
    combinedRqlQuery.aggregate = [];
  }
  return combinedRqlQuery;
}

export function parseRqlQuery<T>(rqlQuery: RQLQuery<T>): string {
  const combinedRqlQuery = createRqlQuery(rqlQuery);
  let result = "";

  // add select
  if (combinedRqlQuery.select && combinedRqlQuery.select.length) {
    const parts = combinedRqlQuery.select.map((v) =>
      createRqlField(v.field, v.alias),
    );
    result += "select(" + parts.join(",") + ")";
  }
  // add sorts
  if (combinedRqlQuery.sort && combinedRqlQuery.sort.length) {
    const parts = combinedRqlQuery.sort.map((v) => {
      return (v.order === "asc" ? "%2B" : "-") + createRqlField(v.field);
    });
    result += "sort(" + parts.join(",") + ")";
  }
  // add limit
  if (combinedRqlQuery.limit) {
    const start = combinedRqlQuery.limit.start
      ? combinedRqlQuery.limit.start
      : 0;
    result += "limit(" + combinedRqlQuery.limit.count + "," + start + ")";
  }
  // append aggregation
  if (combinedRqlQuery.aggregate && combinedRqlQuery.aggregate.length) {
    const parts = [];
    for (const entry of combinedRqlQuery.aggregate) {
      const field = createRqlField(entry.field, entry.alias);
      if (entry.type === "groupBy") {
        parts.push(field);
      } else {
        parts.push(entry.type + "(" + field + ")");
      }
    }
    result += "aggregate(" + parts.join(",") + ")";
  }
  // append where
  if (combinedRqlQuery.where && combinedRqlQuery.where.length) {
    result += processLogicalOperator(combinedRqlQuery.where);
  }
  if (combinedRqlQuery.first && combinedRqlQuery.first === true) {
    result += "one()";
  }
  if (combinedRqlQuery.one && combinedRqlQuery.one === true) {
    result += "one()";
  }
  return result;
}

function processLogicalOperator(
  operators: LogicalConnectableOperator<any>[],
  seperator = "",
) {
  const parts = [];
  for (const entry of operators) {
    if (isLogicalOperator(entry)) {
      parts.push(
        entry.type + "(" + processLogicalOperator(entry.operators, ",") + ")",
      );
    }
    if (isArrayOperator(entry)) {
      parts.push(processArrayOperator(entry));
    }
    if (isScalarOperator(entry)) {
      parts.push(processScalarOperator(entry));
    }
  }
  return parts.join(seperator);
}

function processArrayOperator(arrayOperator: ArrayOperator<any>) {
  if (!arrayOperator.value.length) {
    return "";
  }
  const value = arrayOperator.value
    .map((v) => {
      if (v instanceof Date) {
        return toRqlDate(v);
      }
      return '"' + v + '"';
    })
    .join(",");
  return (
    arrayOperator.type +
    "(" +
    createRqlField(arrayOperator.field) +
    ",(" +
    value +
    "))"
  );
}

function processScalarOperator(logicalOperator: ScalarOperator<any>) {
  let value = logicalOperator.value;
  if (value instanceof Date) {
    value = toRqlDate(value);
  } else if (value === true || value === false) {
    value = value === true ? "true" : "false";
  } else {
    value = '"' + value + '"';
  }
  return (
    logicalOperator.type +
    "(" +
    createRqlField(logicalOperator.field) +
    "," +
    value +
    ")"
  );
}
