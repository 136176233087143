import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TooltipModule } from "primeng/tooltip";
import { DataTableLinkComponent } from "./data-table-link.component";
import { IconButtonModule } from "../../icon-button/icon-button.module";

@NgModule({
  declarations: [DataTableLinkComponent],
  imports: [CommonModule, TooltipModule, IconButtonModule],
  exports: [DataTableLinkComponent],
})
export class DataTableLinkModule {}
