<form [formGroup]="form">
  <form-field [additionalInfoMessage]="currentAdditionalMessage">
    <div class="p-inputgroup">
      <p-autoComplete [suggestions]="autocompleteSuggestions"
                      (completeMethod)="handleSearch($event)"
                      field="label"
                      [delay]="config.searchDelay"
                      [dropdown]="config.dropdownEnabled"
                      [multiple]="config.multipleEnabled"
                      [placeholder]="config.placeholder"
                      [formControl]="form.get('filter')"
                      [pTooltip]="form?.get('filter')?.value?.label"
                      tooltipPosition="bottom"
                      [tooltipDisabled]="!config.selectionTooltipEnabled && form.controls.filter.disabled"
                      [readonly]="!!form?.get('filter')?.value?.label"
                      [showClear]="!!form?.get('filter')?.value?.label"
                      (onClear)="handleUnselect()"
                      [forceSelection]="!form.get('filter').value && config.forceSelection"
                      appendTo="body">
      </p-autoComplete>
    </div>
  </form-field>
</form>
