import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ResourceComponent } from "./resource.component";
import { ResourceUpsertComponent } from "./resource-upsert/resource-upsert.component";
import {
  DataTableModule,
  FormExtensionModule,
  IconButtonModule,
  IncFileUploadModule,
  QuillEditorModule,
} from "@incert/incert-gui";
import { ReactiveFormsModule } from "@angular/forms";
import { DropdownModule } from "primeng/dropdown";
import { MultiSelectModule } from "primeng/multiselect";
import { InputTextModule } from "primeng/inputtext";

@NgModule({
  imports: [
    CommonModule,
    DataTableModule,
    ReactiveFormsModule,
    DropdownModule,
    FormExtensionModule,
    MultiSelectModule,
    IconButtonModule,
    InputTextModule,
    QuillEditorModule,
    IncFileUploadModule,
  ],
  declarations: [ResourceComponent, ResourceUpsertComponent],
  exports: [ResourceComponent],
})
export class ResourceModule {}
