import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TooltipModule } from "primeng/tooltip";
import { ButtonModule } from "primeng/button";
import { DataTableOverlayComponent } from "./data-table-overlay.component";

@NgModule({
  declarations: [DataTableOverlayComponent],
  imports: [CommonModule, TooltipModule, ButtonModule],
  exports: [DataTableOverlayComponent],
})
export class DataTableOverlayModule {}
